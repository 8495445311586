.plan{
  h1{
    background: url(../img/h1_plan.jpg) no-repeat center top;
    span{
      &::after {
        content: 'Plan';
      }
    }
  }
  @media screen and (max-width:768px) {
    h1{
      background-size: cover;
    }
  }
}

.pln_1{
  padding-top: 0;
}

.p_box_1{
  position: relative;
  display: flex;
  margin-bottom: 8%;
  figure{
    width: 50%;
    position: relative;
    z-index: 1;
    img{
      max-width: 110%;
      width: 110%;
    }
  }
  .txt{
    width: 50%;
    background-color: #f4f4f4;
    padding: 3% 2% 3% 2.6%;
    margin-top: 6%;
    position: relative;
    z-index: 2;
    h3{
      font-weight: 900;
      color: #f39800;
    }
    p{
      font-size: 20px;
      font-weight: 600;
    }
  }
  &:nth-child(even){
    flex-flow: row-reverse;
    figure img{
      margin-left: -10%;
    }
  }
  @media screen and (max-width:768px) {
    flex-flow: column;
    figure{
      width: 95%;
      margin-left: 0;
      margin-bottom: 0;
      img{
        max-width: 100%;
        margin: auto;
      }
    }
    .txt{
      width: 95%;
      margin: -10% 0 0 auto;
      p{
        font-size: 4.7vw;
      }
    }
    &:nth-child(even){
      flex-flow: column;
      figure{
        margin-left: 0;
        margin-bottom: 0;
        img{
          margin: auto;
        }
      }
      .txt{
        margin: -10% 0 0 auto;
      }
    }
  }
}

.pln_2{
  background: url(../img/bg_pln_1.jpg) no-repeat center top;
  padding-bottom: 8%;
  h2{
    font-size: 42px;
    line-height: 1.5em;
    em{
      font-style: normal;
      padding-bottom: 7px;
      border-bottom: #f39800 solid 3px;
      display: inline;
    }
    span{
      color: #f39800;
      font-size: 42px;
      display: inline-block;
    }
  }
  .f_box{
    align-items: center;
    figure{
      width: 44%;
      margin-bottom: 0;
      &.arw{
        width: 10%;
      }
      figcaption{
        display: block;
        text-align: left;
        color: #f39800;
        font-size: 24px;
        padding: 1em 0 0 .5em;
      }
    }
  }
  p{
    text-align: center;
    font-size: 20px;
    strong{
      font-size: 36px;
      color: #f39800;
    }
  }
  @media screen and (max-width:768px) {
    background-size: cover;
    h2{
      font-size: 7.3vw;
      line-height: 1.65em;
      span{
        font-size: 7.3vw;
        padding-bottom: .6em;
      }
      em{
        border-width: 2px;
        padding-bottom: .2em;
      }
    }
    p{
      font-size: 4.7vw;
      strong{
        font-size: 6.7vw;
      }
    }
    .f_box{
      figure{
        width: 80%;
        &.arw{
          width: 100%;
          margin: 5% auto;
          img{
            transform: rotate(90deg);
            width: 6%;
          }
        }
        figcaption{
          font-size: 4.7vw;
        }
      }
    }
  }
}

.pln_3{
  background-color: #f4f4f4;
  padding-bottom: 8%;
  h2{
    font-size: 48px;
    color: #f39800;
    span{
      display: block;
      letter-spacing: .1em;
      font-weight: 500;
      padding-bottom: .8em;
    }
  }
  p{
    font-size: 20px;
  }
  @media screen and (max-width:768px) {
    h2{
      font-size: 30px;
    }
    p{
      font-size: 4.7vw;
    }
  }
}

.p_box_2{
  background-color: #fff;
  padding: 4%;
  h3{
    background-color: #f39800;
    font-size: 36px;
    text-align: center;
    padding: .5em;
    color: #fff;
    margin-bottom: .5em;
  }
  table{
    width: 100%;
    border-collapse: collapse;
    th, td{
      border: #999 solid 1px;
      padding: 1em;
      vertical-align: middle;
    }
    th{
      width: 45%;
      font-size: 20px;
      background-color: #f4f4f4;
      font-weight: 400;
    }
    td{
      width: 55%;
      text-align: right;
      font-size: 24px;
      font-weight: 600;
    }
  }
  ul{
    margin-bottom: 2em;
    li{
      position: relative;
      padding-left: 1em;
      font-size: 20px;
      line-height: 1.75em;
      &::before{
        position: absolute;
        content: '●';
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto auto auto 0;
      }
    }
  }
  & + .p_box_2{
    margin-top: 6%;
  }
  @media screen and (max-width:768px) {
    h3{
      font-size: 7.3vw;
    }
    table{
      th{
        font-size: 4.7vw;
      }
      td{
        font-size: 5.6vw;
      }
    }
    ul li{
      font-size: 4.7vw;
    }
  }
}

.p_box_3{
  border: #f39800 solid 4px;
  padding: 0 10% 3%;
  margin-top: 15%;
  h3{
    position: relative;
    text-align: center;
    margin-bottom: -20px;
    padding: 0;
    span{
      position: relative;
      display: inline-block;
      padding: .7em 1.7em;
      background-color: #f39800;
      color: #fff;
      font-size: 30px;
      top: -42px;
    }
  }
  ul{
    li{
      position: relative;
      padding-left: 1em;
      font-size: 20px;
      line-height: 1.75em;
      &::before{
        position: absolute;
        content: '●';
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto auto auto 0;
      }
    }
  }
  @media screen and (max-width:768px) {
    padding: 0 6% 6%;
    h3{
      margin-bottom: -1em;
      span{
        top: -9vw;
      }
    }
    ul li{
      font-size: 4.7vw;
    }
  }
}

.pln_4{
  background: url(../img/bg_pln_2.jpg) no-repeat center top;
  padding: 4% 0;
  h2 {
    color: #fff;
    text-align: center;
    margin-bottom: 1.63em;
    border: none;
    span {
      position: relative;
      font-size: 36px;
      display: inline-block;
      padding: .3em 0;
      line-height: 1.2em;

      &::before,
      &::after {
        position: absolute;
        display: block;
        content: '';
        width: 2px;
        height: 2.3em;
        background-color: #fff;
        top: .3em;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }

      &::before {
        margin-left: -1em;
        transform: rotate(-30deg);
      }

      &::after {
        margin-right: -1em;
        transform: rotate(30deg);
      }
    }
    em{
      font-size: 30px;
      font-style: normal;
    }
    &::after{
      display: none;
    }
  }
  .f_box{
    margin-bottom: 1.5em;
    .w5{
      padding: 0 3% 3%;
      background-color: #fff;
      p{
        text-align: center;
        font-weight: 500;
        &.ttl{
          position: relative;
          margin-bottom: -2.5em;
          span{
            position: relative;
            font-weight: 700;
            font-size: 30px;
            color: #fff;
            padding: .7em 1.7em;
            background-color: #f39800;
            display: inline-block;
            top: -20px;
          }
        }
      }
      h3{
        font-size: 30px;
        text-align: center;
        margin-bottom: .2em;
      }
      figure{
        margin-bottom: .4em;
      }
    }
    & + p{
      color: #fff;
      font-size: 18px;
      margin-bottom: 3em;
    }
  }
  figure.price{
    background-color: #fff;
    padding: 3% 3% 5%;
  }
  @media screen and (max-width:768px) {
    h2{
      span {
        font-size: 5.7vw;
      }
      em{
        font-size: 5.3vw;
      }
    }
    .f_box {
      .w5{
        h3{
          font-size: 6.3vw;
        }
        p{
          &.ttl{
            span{
              font-size: 5.3vw;
            }
          }
        }
        & + .w5{
          margin-top: 10%;
        }
      }
      & + p{
        font-size: 4.7vw;
      }
    }
  }
}

.pln_5{
  padding-bottom: 8%;
  h3{
    background-color: #f4f4f4;
    padding: .4em;
    text-align: center;
    margin-bottom: .5em;
  }
  ul{
    margin-bottom: 3em;
    li{
      position: relative;
      padding-left: 1em;
      font-size: 20px;
      line-height: 1.75em;
      &::before{
        position: absolute;
        content: '・';
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto auto auto 0;
      }
    }
  }
  p{
    font-size: 20px;
    margin-bottom: 3em;
    &:last-child{
      margin-bottom: 0;
    }
  }
  @media screen and (max-width:768px) {
    ul{
      li{
        font-size: 4.7vw;
      }
    }
    p{
      font-size: 4.7vw;
    }
  }
}