.contact{
  h1{
    background: url(../img/h1_about.jpg) no-repeat center top;
    span{
      &::after {
        content: 'Contact';
      }
    }
  }
}

.sec_contact{
  padding-bottom: 6%;
  h2{
    color: #f89503;
  }
  .form_box{
    background-color: #e8e8e8;
    padding: 6% 8% 6% 12%;
    margin-bottom: 2%;
    border-radius: 3px;
    dl{
      display: flex;
      flex-flow: wrap;
      align-items: center;
      dt, dd{
        padding: 2% 0;
        line-height: 1.2em;
      }
      dt{
        width: 30%;
        font-weight: 700;
        font-size: 20px;
        span{
          display: inline-block;
          color: #fff;
          background-color: #000;
          border-radius: 10px;
          padding: .2em 1em;
          margin-top: 5px;
        }
      }
      dd{
        width: 70%;
        font-weight: 700;
        input, select, .wpcf7-list-item-label{
          font-size: 20px;
          line-height: 1.4em;
          @include fg();
          padding: .5em;
          width: 93.5%;
        }
        input, select{
          border: #999 solid 1px;
          border-radius: 2px;
        }
        select{
          padding: .56em;
          margin-left: 3%;
        }
        input[type="date"], select{
          width: 45%;
        }
        input[type="number"]{
          width: 20%;
          margin-right: .5em;
        }
        input[type="radio"], input[type="checkbox"]{
          display: inline-block;
          width: auto;
        }
        input[type="checkbox"] + span{
          padding: .5em 1.5em .5em .1em;
        }
        &.name span input{
          width: 45%;
        }
        &.name span:nth-of-type(2) input{
          margin-left: 3%;
        }
      }
    }
    .box_1{
      background-color: #f2f2f2;
      padding: 3% 5%;
      border-radius: 20px;
      margin: 5% auto 0;
      font-weight: 700;
      p.ttl{
        text-align: center;
      }
      ul{
        padding-left: 5%;
        margin-bottom: 5%;
        li{
          font-size: 20px;
          list-style: disc;
          margin-bottom: .5em;
        }
      }
    }
    .wpcf7-acceptance{
      display: block;
      text-align: center;
      font-size: 20px;
    }
    input[type="submit"]{
      background-color: #f39800;
      color: #fff;
      display: block;
      margin: 5% auto 0;
      font-size: 24px;
      padding: 1em 4em;
      border: none;
      line-height: 1;
      font-weight: 700;
      border-radius: 20px;
    }
  }
  p.end_txt{
    font-weight: 700;
    text-align: center;
    font-size: 18px;
  }
}