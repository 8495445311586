footer {
	text-align: center;
	background-color: #9e9d9d;
	line-height: 1.65em;
	color: #fff;
	padding: 2.65% 0 7%;

	.f_box {
		width: 100%;
		max-width: 1024px;
		padding: 0 12px;
		margin: 0 auto 2.65%;
	}

	.w5 {
		figure {
			margin-bottom: 3%;
			text-align: left;
		}

		p {
			text-align: left;
			line-height: 1.75;
		}
	}

	.f_nav {
		margin-left: 8%;

		ul {
			display: flex;
			flex-flow: column wrap;
			justify-content: space-between;
			max-height: 15em;

			li {
				width: 50%;
				line-height: 1;
				padding: 0;
				margin-top: 1.25em;

				a {
					display: block;
					padding: 0;
					color: #fff;
					font-size: 18px;
					text-decoration: none;
					text-align: left;
				}
			}
		}
	}

	.sns {
		a {
			color: #fff;
			padding: 0 .2em;
		}
	}
	@media screen and (max-width:1299px) {
		.f_nav{
			ul{
				max-height: none;
			}
		}
	}

	@media screen and (max-width:767px) {
		padding-bottom: 20%;

		.f_nav {
			padding: 0;
			margin: 0;

			ul {
				max-height: none;

				li {
					width: 100%;

					a {
						display: block;
						padding: .25em;
						font-size: clamp(14px, 4.3vw, 18px);
					}

					&:not(:first-child) {
						border-left: none;
					}
				}
			}
		}

		.copyright {
			@include fs(12);
		}
	}
}