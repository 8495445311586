.voice{
  h1{
    background: url(../img/h1_voice.jpg) no-repeat center top;
    span{
      &::after {
        content: 'Voice';
      }
    }
  }
}

.sec_vic_1{
  padding-top: 0;
  .item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto 5%;
    border: #f39800 solid 1px;
    padding: 4% 3%;
    figure{
      width: 27%;
      margin: auto auto auto 0;
    }
    .txt_box{
      width: 70%;
    }
  }
  h2{
    font-size: 30px;
    color: #f39800;
    font-weight: 700;
    text-align: left;
    margin-bottom: .7em;
    span{
      color: #1b1b1b;
      font-size: 22px;
      font-weight: 500;
      padding: 0 0 .8em;
      display: inline-block;
    }
  }
  p{
    font-size: 18px;
  }
}

@media screen and (max-width:767px) {
  .voice{
    h1{
      background-size: auto 100%;
    }
  }
  
.sec_vic_1{
  .item{
    flex-flow: column;
    padding: 8% 3%;
    figure{
      width: 60%;
      margin: auto auto 5%;
    }
    .txt_box{
      width: 100%;
    }
  }
  h2{
    font-size: 5.3vw;
    margin-bottom: .7em;
    span{
      font-size: 4.3vw;
      padding: 0 0 .8em;
    }
  }
  p{
    font-size: 3.7vw;
  }
}
}