.data{
  h1{
    background: url(../img/h1_data.jpg) no-repeat center top;
    span{
      &::after {
        content: 'Data';
      }
    }
    @media screen and (max-width:768px) {
      background-size: cover;
      span{
        &::after {
          font-size: 17vw;
          bottom: 0;
        }
      }
    }
  }
}

.dat_1{
  background: url(../img/bg_dat_01.jpg) no-repeat center top;
  padding: 10px 0 3%;
  .inner{
    margin-bottom: 3%;
  }
  .data_ttl{
    margin-bottom: 8%;
  }
  p{
    font-size: 18px;
    margin-bottom: 6%;
  }
  .f_box {
    margin-bottom: 6%;
    .w5 {
      background-color: #fff;
      padding-bottom: 2.3em;
      p {
        font-size: 14px;
        text-align: center;
        margin-bottom: 0;
        &.ttl {
          text-align: center;
          margin-bottom: .5em;
          span {
            position: relative;
            background-color: $color_primary;
            color: #fff;
            font-size: 30px;
            font-weight: 700;
            padding: 0.2em 1.2em;
          }
        }
        &.num {
          font-size: 72px;
          font-weight: 700;
          text-align: center;
          margin-bottom: -.15em;
          span {
            font-size: 30px;
          }
        }
      }
    }
    &.bnr {
      width: 30%;
      margin: 0 auto;
    }
  }
  @media screen and (max-width:767px) {
    background-size: cover;
    p{
      font-size: 4.3vw;
    }
    .f_box {
      .w5 {
        margin-top: 8%;
        p {
          font-size: 3vw;
          &.ttl span {
            font-size: 6.3vw;
          }
          &.num {
            font-size: 16vw;
          }
        }
      }
      &.bnr {
        width: 90%;
        flex-flow: row;
        align-items: center;
        margin-bottom: 7%;
        figure {
          margin: 0;
        }
      }
    }
  }
}

.dat_2{
  background-color: #fff;
  padding-bottom: 10%;
  h2{
    color: #f39800;
    font-size: 48px;
    span{
      font-weight: 400;
      padding: 0 0 .7em;
      letter-spacing: .1em;
    }
  }
  p{
    font-size: 18px;
  }
  .dat_box_1{
    background-color: #f4f4f4;
    padding: 5%;
    margin-bottom: 5px;
    .item{
      background-color: #fff;
      padding: 3%;
      margin-bottom: 5%;
      h3{
        font-size: 24px;
        color: #fff;
        background-color: #9e9d9d;
        padding: .4em;
        text-align: center;
        letter-spacing: .1em;
        font-weight: 500;
      }
      figure{
        margin-bottom: 6%;
        &:last-child{
          margin-bottom: 0;
        }
      }
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width:767px) {
    h2{
      font-size: 7.7vw;
    }
    p{
      font-size: 3.4vw;
    }
  }
}