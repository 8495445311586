@import "reset/_reset.scss";

$color_primary: #f39800;
/* 基本カラーのカラーコードを入れてください */

@import "mixin/_base.scss";
@import "common/_common.scss";
@import "common/_parts.scss";
@import "parts/_about.scss";
@import "parts/_contact.scss";
@import "parts/_data.scss";
@import "parts/_flow.scss";
@import "parts/_footer_01.scss";
@import "parts/_form_01.scss";
@import "parts/_header_01.scss";
@import "parts/_law.scss";
@import "parts/_news.scss";
@import "parts/_plan.scss";
@import "parts/_privacy.scss";
@import "parts/_sitemap.scss";
@import "parts/_voice.scss";

ul.ul_x {
  li {
    font-size: 18px;
    padding-left: 1.7em;
    line-height: 1.333;
    background: url(../img/icon_x.png) no-repeat 0 0;
    margin-bottom: .6em;
  }
}

ul.ul_chk {
  margin-top: -2em;

  li {
    font-size: 20px;
    background: url(../img/icon_chk.png) no-repeat 0 .3em;
    padding-left: 1.7em;
    margin-bottom: .5em;
    line-height: 1.4em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.mv {
  position: relative;
  padding-bottom: 180px;

  .mv_img {
    text-align: right;
    overflow: hidden;
    width: 100%;

    img {
      margin: 0 auto;
    }
  }

  .catch {
    position: absolute;
    top: 22.4%;
    left: 14.9%;

    p {
      text-shadow: 0 0 3px #fff, 0 0 4px #fff, 0 0 5px #fff, 0 0 5px #fff;
      font-weight: 700;

      &.txt_1 {
        font-size: 30px;
        margin-bottom: .45em;
        letter-spacing: .1em;
      }

      &.txt_2 {
        font-size: 48px;
        line-height: 1.35em;
        margin: 0 0 .25em -.1em;

        span {
          color: $color_primary;
          position: relative;
          font-size: 55px;
          left: -.6em;
        }
      }

      &.txt_3 {
        font-weight: 600;
        font-size: 24px;
        margin: 0 0 0 -.5em;
        line-height: 1.75em;

        span {
          color: $color_primary;
        }
      }
    }
  }

  .btn {
    position: fixed;
    top: 71%;
    right: 1%;
    z-index: 3;

    .more {
      margin-bottom: 12px;

      a {
        background-color: #35bc27;
        width: 295px;
        max-width: none;
        height: 100px;
        color: #fff;
        font-weight: 800;
        font-size: 24px;
        padding-top: 36px;
        border-radius: 6px;

        &.inv {
          background-color: $color_primary;
          padding-top: 23px;
        }
      }
    }
  }

  .txt {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto auto 10px;
    width: 700px;
    background-color: #fff;
    border: #999 solid 1px;
    box-shadow: 0 0 6px rgba(#999, .6);

    p.ttl {
      font-size: 48px;
      font-weight: 800;
      text-align: center;
      line-height: 1;
      padding: 3.8% 0 3.5%;
      margin-bottom: 0;

      span {
        font-size: 80px;
        color: $color_primary;

        &.sml {
          font-size: 72px;
          position: relative;
          left: 5px;
        }

        &.sml2 {
          font-size: 60px;
          color: #333;
          position: relative;
          left: 5px;
        }
      }
    }

    .box {
      background-color: #f4f4f4;
      padding: 2% 3% 3% 7.4%;

      p {
        font-size: 14px;
        line-height: 1.75em;
      }
    }

  }
  @media screen and (max-width:1024px) {
    .mv_img {
      img{
        width: 120%;
      }
    }
    .catch{
      top: 10%;
      left: 5%;
      p.txt_1{
        font-size: 3vw;
      }
      p.txt_2{
        font-size: 3.8vw;
        span{
          font-size: 5vw;
        }
      }
      p.txt_3{
        font-size: 2.2vw;
      }
    }
    .btn {
      top: 79%;
      .more a{
        font-size: 18px;
        width: 220px;
        height: 60px;
        padding-top: 16px;
        line-height: 1.4em;
        &.inv{
          padding-top: 3px;
        }
      }
    }
  }
  @media screen and (max-width:767px) {
    padding-bottom: 50vw;

    .mv_img {
      overflow: hidden;
      width: 100%;

      img {
        margin-left: -40%;
        width: 170%;
      }
    }

    .catch {
      left: 5%;
      top: 8%;

      p {

        &.txt_1 {
          font-size: 4.7vw;
          margin-left: -0.2em;;
        }

        &.txt_2 {
          font-size: 5.3vw;
          margin-left: -0.3em;
          text-indent: -0.1em;

          span {
            font-size: 6.7vw;
            left: -.4em;
          }
        }

        &.txt_3 {
          font-size: 4.3vw;
          margin-left: -0.3em;
          text-indent: -0.4em;
        }
      }
    }

    .btn {
      top: inherit;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto auto 0;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      height: 18.5vw;
      background-color: rgba(#999, .6);
      width: 100vw;
      padding: 2vw;

      .more {
        width: 49%;

        a {

          top: 0;
          font-size: 4.3vw;
          width: 100%;
          height: 15vw;
          padding: 4.5vw 10vw 0 2vw;
          line-height: 1.4em;

          &.inv {
            padding-top: 1vw;
          }
        }
      }
    }

    .txt {
      width: 94vw;

      p.ttl {
        font-size: 6.3vw;

        span {
          font-size: 8.3vw;

          &.sml {
            font-size: 7.7vw;
          }

          &.sml2 {
            font-size: 7.3vw;
          }
        }
      }

      .box {
        padding: 3%;

        p {
          font-size: 3.4vw;
        }
      }
    }
  }
}

.sec_top_1 {
  background: url(../img/bg_01.jpg) no-repeat center bottom;
  padding: 147px 0 50px;

  h2 {
    background-color: #fff;
    margin-bottom: 96px;
    color: #333;
    text-shadow:
      0px 0px 8px rgba(255, 255, 255, 1),
      0px 0px 8px rgba(255, 255, 255, 1),
      0px 0px 4px rgba(255, 255, 255, 1),
      0px 0px 4px rgba(255, 255, 255, 1);

    &::after {
      content: 'Can we stop now?';
      color: $color_primary;
    }
  }

  .f_box .w5 {
    background-color: #fff;
    padding: 4.7% 2.5% 2%;
    margin-bottom: 9%;

    figure {
      margin-bottom: 12px;
    }

    h3 {
      text-align: center;
      font-weight: 800;
      font-size: clamp(24px, 3.7vw, 30px);
      border: none;
      background: url(../img/ttl_bg_2.png) no-repeat center bottom;
      padding-bottom: .75em;
    }
  }
  @media screen and (max-width:1024px) {
    background-size: auto 80%;
    h2 {
      &::after {
        font-size: 14vw;
      }
    }
  }

  @media screen and (max-width:767px) {
    background-repeat: repeat-y;
    background-size: 200% auto;
    padding-top: 0;

    h2 {
      margin-bottom: 2em;
      padding: 1.5em 0 2em;

      &::after {
        font-size: clamp(80px, 22vw, 90px);
        bottom: 1.4em;
      }
    }

    .f_box .w5 {
      ul li {
        font-size: 4.7vw;
      }

      h3 {
        font-size: 6.4vw;
        background-size: 70%;
      }
    }
  }
}

.sec_top_2 {
  background-color: #9e9d9d;
  padding: 3.6% 0 3%;

  h3 {
    color: $color_primary;
    text-align: center;
    margin-bottom: 1.63em;
    border: none;
    text-shadow:
      1px 1px 0 #FFF,
      -1px -1px 0 #FFF,
      -1px 1px 0 #FFF,
      1px -1px 0 #FFF,
      0px 1px 0 #FFF,
      0 -1px 0 #FFF,
      -1px 0 0 #FFF,
      1px 0 0 #FFF;

    span {
      position: relative;
      font-size: 36px;

      &::before,
      &::after {
        position: absolute;
        display: block;
        content: '';
        width: 2px;
        height: 2.3em;
        background-color: #fff;
        top: .3em;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }

      &::before {
        margin-left: -2em;
        transform: rotate(-30deg);
      }

      &::after {
        margin-right: -2em;
        transform: rotate(30deg);
      }
    }
  }

  .f_box {
    align-items: center;
    margin-bottom: 7.5%;

    .w5 p {
      font-size: 18px;
      color: #fff;
      line-height: 2;
    }
  }

  .box_1 {
    background-color: #fff;
    padding: 3% 2.5%;

    p.ttl {
      text-align: center;
      margin-bottom: 0;
      position: relative;
      top: -3.4em;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto auto;

      span {
        display: inline-block;
        padding: 2.4% 5%;
        font-size: 30px;
        font-weight: 600;
        color: #fff;
        background-color: $color_primary;
      }
    }
  }
  @media screen and (max-width:1024px) {
    h3{
      margin-bottom: 2%;
      span{
        font-size: clamp(20px, 3.7vw, 30px);
        &::before {
          margin-left: -1em;
        }

        &::after {
          margin-right: -1em;
        }
      }
    }
    .box_1 p.ttl{
      top: -3.5em;
      span{
        font-size: 3vw;
      }
    }
  }
  @media screen and (max-width:767px) {
    h3 {
      margin-bottom: 1.63em;

      span {
        font-size: 5.7vw;

        &::before {
          margin-left: -.5em;
        }

        &::after {
          margin-right: -2.5em;
        }
      }
    }

    .f_box .w5 p {
      font-size: 4.7vw;
    }

    .box_1 {
      padding: 5% 2.5%;

      p.ttl span {
        font-size: 5.7vw;
      }

      ul li {
        font-size: 4.7vw;
        line-height: 1.4em;
      }
    }
  }
}

.sec_top_3 {
  background-color: #f4f4f4;
  padding: 7.25% 0 4%;

  h2 {
    font-size: 38px;
    line-height: 1.333;
    letter-spacing: -.04em;
    margin-bottom: 2em;

    span {
      font-size: 38px;
      color: $color_primary;
      display: inline;
    }

    &::after {
      content: 'Reason';
      font-size: 160px;
      bottom: .4em;
    }
  }

  .f_box {
    .w25 {
      border: $color_primary solid 2px;
      padding: 0;
      background-color: #fff;

      .num {
        position: relative;
        font-size: 36px;
        font-weight: 600;
        padding: .25em 0 0 .55em;
        border-radius: 100%;
        color: #fff;
        background-color: $color_primary;
        width: 1.7em;
        height: 1.7em;
        display: block;
        margin: 0 auto auto;
        top: -.85em;
        margin-bottom: -.2em;
      }

      figure {
        margin-bottom: 1.3em;
      }

      p {
        text-align: center;
        font-weight: 600;
        line-height: 1.333;
        font-size: 18px;
        margin-bottom: 1.3em;
      }
    }
  }
  @media screen and (max-width:1024px) {
    h2{
      font-size: 4.7vw;
      &::after {
        font-size: 13vw;
        bottom: 0.65em;
      }
    }
    .f_box .w25 p{
      font-size: 1.7vw;
    }
  }

  @media screen and (max-width:767px) {
    h2 {
      font-size: 5.7vw;

      span {
        font-size: 6.3vw;
      }

      &::after {
        font-size: 25vw;
        bottom: 0.45em;
      }
    }
    .f_box .w25{
      width: 80%;
      margin: 5% auto;
      p{
        font-size: 4.7vw;
      }
    }
  }
}

.sec_top_4 {
  padding: 4.3% 0 4%;

  h3 {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    line-height: 1.25;
    margin-bottom: .8em;

    span {
      color: $color_primary;
    }

    em {
      font-style: normal;
      border-bottom: $color_primary solid 4px;
      padding-bottom: 3px;
    }
  }

  .f_box {
    max-width: 910px;
    margin: 0 auto 1.8em;

    .w33 {
      border: $color_primary solid 4px;

      p {
        font-size: 24px;
        font-weight: 700;
        color: $color_primary;
        text-align: center;
        line-height: 1.5;
        padding: 1.3em 0;
      }
    }
  }

  p {
    font-size: 112.5%;
  }
  @media screen and (max-width:1024px) {
    h3 {
      font-size: 4.8vw;
      span{
        font-size: 4.8vw;
      }
    }
  }

  @media screen and (max-width:767px) {
    h3 {
      font-size: 5.3vw;

      span {
        font-size: 5.7vw;
      }

      em {
        border-width: 2px;
      }
    }

    .f_box {

      .w33 {
        border: $color_primary solid 2px;

        p {
          font-size: 6vw;
          padding: .7em 0;
        }
      }
    }
  }
}

.sec_top_5 {
  padding: 5% 0 4%;

  h2 {
    font-size: 36px;
    line-height: 1.333;
    letter-spacing: -.04em;
    margin-bottom: 1.3em;

    span {
      font-size: 60px;
      color: $color_primary;
      display: inline;
    }

    &::after {
      content: 'Features';
      bottom: .05em;
    }
  }

  p.more {
    padding-top: 5%;

    a {
      border-radius: 6px;
      font-size: 20px;
      font-weight: 600;
      max-width: 400px;
      margin: 0 auto;
      padding: 1.3em 0;
    }
  }
  @media screen and (max-width:1024px) {
    h2::after{
      bottom: 2vw;
    }
  }

  @media screen and (max-width:767px) {
    h2 {
      font-size: 7.4vw;

      span {
        font-size: 10.4vw;
      }
    }
  }
}

.box_2 {
  position: relative;
  margin-bottom: 9.7%;

  figure {
    text-align: left;
  }

  .txt {
    position: absolute;
    background-color: #f4f4f4;
    width: 45%;
    padding: 0 2% 5% 4.5%;
    top: 37%;

    h3 {
      position: relative;
      font-family: impact, sans-serif;
      top: -1.55em;
      font-size: 36px;
      line-height: 1.2;
      padding: 0;
      margin-bottom: 0;
      letter-spacing: .05em;
      color: #222;

      span {
        font-size: 72px;
      }
    }

    h4 {
      font-size: 30px;
      color: $color_primary;
      line-height: 1.2;
      font-weight: 800;
      margin: -1em 0 .7em;
    }

    p {
      font-size: 20px;
      font-weight: 600;
    }
  }

  &:nth-last-of-type(odd) {
    .txt {
      right: 0;

      h3 {
        text-align: right;
        margin-right: -2%;
      }
    }
  }

  &:nth-last-of-type(even) {
    margin-bottom: 13%;

    figure {
      text-align: right;
    }

    .txt {
      left: 0;
      padding-bottom: 3%;

      h3 {
        text-align: left;
        margin-left: -8%;
      }
    }
  }
  @media screen and (max-width:1024px) {
    .txt{
      h4{
        font-size: 3vw;
      }
      p{
        font-size: 2vw;
      }
    }
  }

  @media screen and (max-width:767px) {
    figure {
      margin-bottom: 0;
    }

    .txt {
      position: relative;
      width: 100%;

      h3 {
        top: -1.2em;
        font-size: 8vw;
      }

      h4 {
        font-size: 6.3vw;
      }

      p {
        font-size: 4.3vw;
      }
    }

    &:nth-last-of-type(odd) .txt h3 {
      margin-right: 3%;
    }

    &:nth-last-of-type(even) .txt h3 {
      margin-left: 0;
    }
  }
}

.sec_top_6 {
  padding: 10.8% 0 3.5%;
  background: url(../img/bg_02.jpg) no-repeat center bottom;

  h2 {
    font-size: 36px;
    line-height: 1.25;
    letter-spacing: -.04em;
    margin-bottom: 1.3em;

    span {
      font-size: 48px;
      display: inline;
    }

    em {
      color: $color_primary;
      font-style: normal;
    }

    &::after {
      content: 'Plan';
      bottom: .5em;
    }
  }

  p.t_r {
    font-weight: 700;
    margin-bottom: 3em;
  }

  p.more a.arw {
    max-width: 400px;
    padding: 1.35em 0;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 800;
  }

  @media screen and (max-width:1024px) {
    background-size: auto 100%;
  }

  @media screen and (max-width:767px) {
    padding-top: 18%;

    h2 {
      font-size: 5.7vw;

      span {
        font-size: 7.2vw;
      }
    }
  }
}

.tbl_price {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: .5em;

  th,
  td {
    border-right: #ccc solid 2px;
    border-bottom: #ccc solid 2px;
    width: 25%;
    height: 3.56em;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;

    span {
      font-size: 24px;
    }
  }

  thead th {
    background-color: $color_primary;
    color: #fff;
    border-top: #ccc solid 2px;
    font-size: 24px;
    height: 4.2em;

    &:first-child {
      background: transparent;
      border-top: none;
    }
  }

  tbody {
    th {
      background-color: #9e9d9d;
      color: #fff;
      border-left: #ccc solid 2px;
      font-size: 24px;
    }

    td {
      background-color: #fff;
      color: #333;
    }
  }
  @media screen and (max-width:1024px) {
    thead th {
      font-size: clamp(13px, 2.5vw, 24px);
    }
    tbody{
      th, td{
        font-size: clamp(13px, 2.7vw, 24px);
        span{
          font-size: clamp(13px, 2.3vw, 24px);
        }
      }
    }
  }

  @media screen and (max-width:767px) {

    th,
    td {
      span {
        font-size: 3vw;
      }
    }

    thead th {
      font-size: 3vw;
    }

    tbody {
      th {
        font-size: 3vw;
      }

      td {
        font-size: 3vw;
      }
    }
  }
}

.sec_top_7 {
  padding: 9.5% 0 2.7%;

  h2 {
    margin-bottom: 1.5em;

    &::after {
      content: 'Q&A';
    }
  }

  @media screen and (max-width:767px) {
    padding-top: 22.5%;
  }
}

dl.faq {
  border: #ccc solid 2px;
  padding: 2% 2% 0 4.5%;

  dt,
  dd {
    position: relative;

    span {
      position: absolute;
      display: block;
      top: 0;
      left: -1.3em;
    }
  }

  dt {
    font-size: 137.5%;
    font-weight: 600;
    color: $color_primary;
    background: none;
    padding: 0 0 2%;
    margin-bottom: 0;

    &::before,
    &::after {
      background-color: $color_primary;
      height: 2px;
      width: 20px;
    }

    &.active {}
  }

  dd {
    font-size: 112.5%;
    padding: 3.1% 0 0;
    background: none;
    font-weight: 500;
    line-height: 1.667;

    span {
      top: 1.6em;
    }
  }

  @media screen and (max-width:767px) {
    padding-left: 10.5%;

    dt {
      padding-right: 13%;
    }

    dd {
      padding-top: 8%;
      margin-bottom: 1em;
    }
  }
}

.sec_top_8 {
  padding: 3.65% 0 3.5%;
  background: url(../img/bg_03.jpg) no-repeat center bottom;

  h3 {
    font-size: 36px;
    text-align: center;
    line-height: 1.333;
    margin-bottom: -.2em;

    span {
      font-weight: 800;
      font-size: 48px;
      color: $color_primary;
    }
  }

  p.t_c {
    margin-bottom: 3.8em;
  }

  .f_box {
    margin-bottom: 6%;

    .w5 {
      background-color: #fff;
      padding-bottom: 2.3em;

      p {
        font-size: 14px;
        text-align: center;

        &.ttl {
          text-align: center;
          margin-bottom: .5em;

          span {
            position: relative;
            background-color: $color_primary;
            color: #fff;
            font-size: 30px;
            font-weight: 700;
            padding: 0.2em 1.2em;
          }
        }

        &.num {
          font-size: 72px;
          font-weight: 700;
          text-align: center;
          margin-bottom: -.15em;

          span {
            font-size: 30px;
          }
        }
      }
    }

    &.bnr {
      width: 30%;
      margin: 0 auto;
    }
  }

  figure {
    margin-bottom: 1.5em;
  }

  p.more a.arw {
    max-width: 400px;
    padding: 1.35em 0;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 800;
  }

  @media screen and (max-width:767px) {
    background-size: auto 100%;

    h3 {
      font-size: 5.7vw;

      span {
        font-size: 7.3vw;
      }
    }

    p.t_c {
      font-size: 3vw;
    }

    .f_box {
      .w5 {
        margin-top: 8%;

        p {
          font-size: 3vw;

          &.ttl span {
            font-size: 6.3vw;
          }

          &.num {
            font-size: 16vw;
          }
        }
      }

      &.bnr {
        width: 90%;
        flex-flow: row;
        align-items: center;
        margin-bottom: 7%;

        figure {
          margin: 0;
        }
      }
    }
  }
}

.sec_top_9 {
  padding: 7.3% 0 10%;

  h2 {
    margin-bottom: 1.5em;

    &.news::after {
      content: 'News';
    }

    &.blog::after {
      content: 'Blog';
    }
  }

  ul li {
    margin-bottom: 2.4em;

    span {
      padding-right: 1em;
    }

    a {
      font-size: 18px;
      font-weight: 500;
    }
  }

  p.more a.arw {
    max-width: 400px;
    padding: 1.35em 0;
    margin: 2.6em auto 0;
    font-size: 20px;
    font-weight: 800;
  }
  @media screen and (max-width:767px) {
    h2 {
      margin-top: 20%;
    }

    ul li {
      span {
        display: block;
        margin-bottom: .5em;
      }
    }
  }
}

.sec_top_10 {
  padding: 5.3% 0 0;
  background-color: #f4f4f4;

  h3 {
    margin-bottom: 0;

    span {
      font-size: 48px;

      &.clr {
        color: $color_primary;
      }
    }
  }

  figure {
    position: relative;

    img {
      margin-top: -48%;
    }
  }

  p.more a.arw {
    max-width: 400px;
    padding: 1.35em 0;
    margin: 1em auto 0 1.5em;
    font-size: 20px;
    font-weight: 800;
  }

  @media screen and (max-width:1024px) {
    figure img{
      width: 70%;
      margin-top: -40%;
    }
  }

  @media screen and (max-width:767px) {
    padding-top: 10%;

    h3 {
      text-align: center;

      span {
        font-size: 6vw;
      }
    }

    figure {

      img {
        margin-top: -18%;
        width: 50vw;
      }
    }

    p.more a.arw {
      max-width: 90vw;
      margin: 0 auto;
    }
  }
}

.sec_top_11 {
  background: url(../img/bg_04.jpg) no-repeat center top;
  padding: 7% 0 2.5%;

  h2 {
    margin-bottom: 1em;
    color: #fff;

    &::after {
      content: 'Contact';
      color: $color_primary;
    }
  }

  p {
    text-align: center;
    font-size: 24px;
    color: #fff;
    font-weight: 600;
  }

  .f_box {
    width: 94%;
    margin: 0 auto;
  }

  p.more a.arw {
    max-width: 400px;
    padding: 1.5em 0;
    margin: .6em auto 0 1.5em;
    font-size: 20px;
    font-weight: 800;

    &.line {
      background-color: #35bc27;
    }
  }
  @media screen and (max-width:1024px) {
    p.more a.arw{
      margin: 0.6em auto;
    }
  }

  @media screen and (max-width:767px) {
    padding: 20% 0 0;
    background-position: 80% top;

    p {
      font-size: 4.7vw;
    }

    p.more a.arw {
      margin: 0 auto;
      padding: 1em;
    }
  }
}