@charset "UTF-8";
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, caption, tfoot, thead, th, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  color: #00449a;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

ol li, ul li {
  list-style: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* 基本カラーのカラーコードを入れてください */
@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
  .sp {
    display: block !important;
  }
}
@media screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
  .pc {
    display: block !important;
  }
}
html {
  font-size: 62.5%;
}

body {
  line-height: 1;
  font-size: 16px;
  font-size: 1.6rem;
  color: #333;
  -webkit-text-size-adjust: 100%;
  max-width: 1920px;
  font-family: "Noto Sans JP", sans-serif;
}
@media screen and (max-width: 767px) {
  body {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

h1 {
  background: url(../img/h1_about.jpg) no-repeat center top;
  height: 300px;
  display: flex;
  align-items: center;
}
h1 span {
  position: relative;
  width: 100%;
  max-width: 1000px;
  display: block;
  margin: 0 auto;
  text-align: left;
  font-size: 36px;
  font-weight: 600;
  padding-top: 2em;
  color: #fff;
}
h1 span i {
  position: relative;
  z-index: 2;
  font-style: normal;
}
h1 span::after {
  display: block;
  position: absolute;
  content: "";
  font-family: "allura", sans-serif;
  font-weight: 400;
  width: 100%;
  height: 1em;
  font-size: 150px;
  color: #f39800;
  top: 0;
  right: 0;
  bottom: -11px;
  left: -52px;
  margin: auto auto 0;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  h1 {
    padding: 2em 0.5em;
    height: auto;
    background-size: cover;
  }
  h1 span {
    font-size: 6.3vw;
  }
  h1 span::after {
    font-size: 27vw;
    bottom: -20%;
    left: 0;
  }
}

h2 {
  margin-bottom: 1.2em;
  font-size: 38px;
  font-size: 3.8rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  z-index: 2;
}
h2 span {
  padding-top: 0.4em;
  display: block;
  font-size: 18px;
  font-size: 1.8rem;
}
h2::after {
  display: block;
  position: absolute;
  content: "";
  font-family: "allura", sans-serif;
  font-weight: 400;
  width: 100%;
  height: 1em;
  font-size: 150px;
  color: #e5e5e5;
  top: 0;
  right: 0;
  bottom: -0.12em;
  left: 0;
  margin: auto auto 0;
  z-index: -1;
}
@media screen and (max-width: 1024px) {
  h2 {
    font-size: 3.5vw;
  }
  h2::after {
    font-size: 11vw;
  }
  h2 span {
    font-size: 16px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 767px) {
  h2 {
    font-size: 30px;
    font-size: 3rem;
  }
  h2::after {
    font-size: 25vw;
  }
  h2 span {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

h3 {
  font-size: 28px;
  font-size: 2.8rem;
  padding: 0 0 0.3em;
  margin-bottom: 1em;
  line-height: 1.3em;
}
@media screen and (max-width: 768px) {
  h3 {
    font-size: 5.3vw;
  }
  h3 span {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

section {
  padding: 5% 0 0;
}
section .s_hdr {
  display: flex;
  width: 100%;
  align-items: center;
}
section .inner {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto 5%;
  padding: 0 12px;
}
section .inner:last-of-type {
  margin-bottom: 0;
}
section .inner::after {
  content: "";
  display: block;
  clear: both;
}
@media screen and (max-width: 767px) {
  section {
    padding: 10% 0;
  }
  section .inner {
    padding: 0 3%;
  }
}

figure {
  width: 100%;
  margin: 0 auto 2em;
  text-align: center;
  line-height: 0;
}
figure img {
  max-width: 100%;
  margin: 0 auto;
  line-height: 1;
}
figure figcaption {
  padding-top: 0.5em;
}
figure:last-child {
  margin-bottom: 0;
}

p {
  margin-bottom: 1.5em;
  line-height: 1.65em;
}
p:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  p {
    /*font-size: 3.7vw; 14px*/
    font-size: 4.2vw;
    font-size: clamp(14px, 4.2vw, 18px);
    /*16px*/
    /*font-size: 4.8vw; 18px*/
    /*font-size: 5.3vw; 20px*/
  }
}

a {
  color: #333;
  text-decoration: none;
}

article {
  margin-bottom: 3%;
}
article .date {
  float: right;
  padding: 3%;
  margin: 0;
}
article h3 {
  font-weight: 500;
  font-size: 20px;
  padding: 0;
  margin: 0;
}
article h3 a {
  padding: 3%;
  text-decoration: none;
  display: block;
  transition-duration: 0.5s;
  border-radius: 6px;
}
article h3 a:hover {
  background-color: #f4f4f4;
}
article:nth-child(odd) h3 a {
  background-color: rgba(243, 152, 0, 0.2);
}
article:nth-child(odd) h3 a:hover {
  background-color: rgba(243, 152, 0, 0.4);
}

nav.bc_list {
  margin: 10px 10px 5%;
}
nav.bc_list ol {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
}
nav.bc_list ol li {
  margin-right: 1em;
  position: relative;
}
nav.bc_list ol li a {
  font-size: 16px;
}
nav.bc_list ol li:not(:last-child)::after {
  display: inline-block;
  content: "";
  width: 0.4em;
  height: 0.4em;
  border-right: #333 solid 2px;
  border-bottom: #333 solid 2px;
  transform: rotate(-45deg);
  margin: 0 0 1px;
}
@media (max-width: 768px) {
  nav.bc_list ol li a {
    font-size: 3.4vw;
  }
  nav.bc_list ol li:not(:last-child)::after {
    border-width: 1px;
  }
}

.lnk_tel {
  text-decoration: none;
}
@media (min-width: 768px) {
  .lnk_tel {
    pointer-events: none;
    cursor: default;
  }
}

.box_video {
  position: relative;
  width: 100%;
  height: 56.25%;
  padding: 56.25% 0 0 0;
  text-align: center;
}
.box_video .video_wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  margin: 0 auto;
}
.box_video .video_wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ripple {
  position: relative;
  display: block;
}
.ripple img {
  max-width: 100%;
  margin: 0 auto;
}
.ripple span {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 90%;
  height: 100%;
  margin: auto;
  animation-name: rippleAnimation;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  border-radius: 100px;
  background-color: #fff072;
  box-shadow: 0 0 5px white;
}

@-webkit-keyframes rippleAnimation {
  0% {
    transform: scale(1, 1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}
@keyframes rippleAnimation {
  0% {
    transform: scale(1, 1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}
.ripple2 {
  position: relative;
  display: block;
}
.ripple2 img {
  max-width: 100%;
  margin: 0 auto;
}
.ripple2 span.clr_1, .ripple2 span.clr_2 {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 70px;
  height: 70px;
  margin: auto;
  -webkit-animation-name: rippleAnimation2;
  animation-name: rippleAnimation2;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 100%;
  box-shadow: 0 0 45px 1px #ff004a inset;
}
.ripple2 span.clr_2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes rippleAnimation2 {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  80% {
    transform: scale(4.5, 4.5);
    opacity: 0;
  }
  100% {
    transform: scale(4.5, 4.5);
    opacity: 0;
  }
}
@keyframes rippleAnimation2 {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  80% {
    transform: scale(4.5, 4.5);
    opacity: 0;
  }
  100% {
    transform: scale(4.5, 4.5);
    opacity: 0;
  }
}
.bounce {
  position: relative;
}
.bounce::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100px;
  height: 50px;
  margin: 0 auto auto;
  content: "";
  background: url(../img/down.png) no-repeat center top;
  animation-name: bounce;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

.pagination {
  margin: 40px 0 0;
}
.pagination .page-numbers {
  display: inline-block;
  margin-right: 20px;
  padding: 20px 25px;
  color: #333;
  border-radius: 3px;
  box-shadow: 0 3px 3px #999;
  background: #fff;
}
.pagination .current {
  padding: 20px 25px;
  background: #69a4db;
  color: #fff;
}
.pagination .prev,
.pagination .next {
  background: transparent;
  box-shadow: none;
  color: #69a4db;
}
.pagination .dots {
  background: transparent;
  box-shadow: none;
}

.nav-links {
  display: flex;
  padding: 5px 0;
}

.breadcrumbs {
  width: 100%;
  max-width: 1000px;
  padding: 1%;
  display: block;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .breadcrumbs {
    padding: 3% 5%;
  }
}

.Fade-In {
  opacity: 0;
  transform: translateY(20px);
  transition: all 1s;
}
.Fade-In.Fade-In-2 {
  transition-delay: 0.2s;
}
.Fade-In.Fade-In-3 {
  transition-delay: 0.3s;
}
.Fade-In.Fade-In-4 {
  transition-delay: 0.4s;
}

.Fade-In-Down {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 1s;
}
.Fade-In-Down.Fade-In-Down-2 {
  transition-delay: 0.2s;
}
.Fade-In-Down.Fade-In-Down-3 {
  transition-delay: 0.3s;
}
.Fade-In-Down.Fade-In-Down-4 {
  transition-delay: 0.4s;
}

.Fade-In-Left {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 1s;
}
.Fade-In-Left.Fade-In-Left-2 {
  transition-delay: 0.2s;
}
.Fade-In-Left.Fade-In-Left-3 {
  transition-delay: 0.3s;
}
.Fade-In-Left.Fade-In-Left-4 {
  transition-delay: 0.4s;
}

.Fade-In-Right {
  opacity: 0;
  transform: translateX(20px);
  transition: all 1s;
}
.Fade-In-Right.Fade-In-Right-2 {
  transition-delay: 0.2s;
}
.Fade-In-Right.Fade-In-Right-3 {
  transition-delay: 0.3s;
}
.Fade-In-Right.Fade-In-Right-4 {
  transition-delay: 0.4s;
}

.t_1 {
  color: #f39800;
}

.t_2 {
  color: #c00;
}

.t_3 {
  color: #c00;
}

.t_4 {
  color: #f39800;
}

.t_5 {
  color: #c00;
}

.t_1_b {
  padding: 0.1em;
  color: #c00;
  background-color: #ff0;
}

.t_2_b {
  padding: 0.1em;
  color: #c00;
  background-color: #ff0;
}

.t_3_b {
  padding: 0.1em;
  color: #c00;
  background-color: #ff0;
}

.t_4_b {
  padding: 0.1em;
  color: #c00;
  background-color: #ff0;
}

.t_5_b {
  padding: 0.1em;
  color: #c00;
  background-color: #ff0;
}

.t_lrg {
  font-size: 130%;
}

.t_xlrg {
  font-size: 170%;
}

.t_stg {
  font-weight: bold;
}

.t_ul {
  text-decoration: underline;
}

.t_c {
  text-align: center;
}

.t_r {
  text-align: right;
}

.t_l {
  text-align: left;
}

.f_l {
  float: left;
  margin-right: 1em;
  width: auto;
}

.f_r {
  float: right;
  margin-left: 1em;
  width: auto;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mb110 {
  margin-bottom: 110px !important;
}

.mb120 {
  margin-bottom: 120px !important;
}

.mb130 {
  margin-bottom: 130px !important;
}

.mb140 {
  margin-bottom: 140px !important;
}

.mb150 {
  margin-bottom: 150px !important;
}

.mb160 {
  margin-bottom: 160px !important;
}

.mb170 {
  margin-bottom: 170px !important;
}

.mb180 {
  margin-bottom: 180px !important;
}

.mb190 {
  margin-bottom: 190px !important;
}

.mb200 {
  margin-bottom: 200px !important;
}

.mb210 {
  margin-bottom: 210px !important;
}

.mb220 {
  margin-bottom: 220px !important;
}

.mb230 {
  margin-bottom: 230px !important;
}

.mb240 {
  margin-bottom: 240px !important;
}

.mb250 {
  margin-bottom: 250px !important;
}

.mb260 {
  margin-bottom: 260px !important;
}

.mb270 {
  margin-bottom: 270px !important;
}

.mb280 {
  margin-bottom: 280px !important;
}

.mb290 {
  margin-bottom: 290px !important;
}

.mb300 {
  margin-bottom: 300px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.pb70 {
  padding-bottom: 70px !important;
}

.pb80 {
  padding-bottom: 80px !important;
}

.pb90 {
  padding-bottom: 90px !important;
}

.pb100 {
  padding-bottom: 100px !important;
}

.pb110 {
  padding-bottom: 110px !important;
}

.pb120 {
  padding-bottom: 120px !important;
}

.pb130 {
  padding-bottom: 130px !important;
}

.pb140 {
  padding-bottom: 140px !important;
}

.pb150 {
  padding-bottom: 150px !important;
}

.pb160 {
  padding-bottom: 160px !important;
}

.pb170 {
  padding-bottom: 170px !important;
}

.pb180 {
  padding-bottom: 180px !important;
}

.pb190 {
  padding-bottom: 190px !important;
}

.pb200 {
  padding-bottom: 200px !important;
}

.pb210 {
  padding-bottom: 210px !important;
}

.pb220 {
  padding-bottom: 220px !important;
}

.pb230 {
  padding-bottom: 230px !important;
}

.pb240 {
  padding-bottom: 240px !important;
}

.pb250 {
  padding-bottom: 250px !important;
}

.pb260 {
  padding-bottom: 260px !important;
}

.pb270 {
  padding-bottom: 270px !important;
}

.pb280 {
  padding-bottom: 280px !important;
}

.pb290 {
  padding-bottom: 290px !important;
}

.pb300 {
  padding-bottom: 300px !important;
}

.more a {
  display: block;
  width: 100%;
  max-width: 280px;
  color: #fff;
  background-color: #f39800;
  text-decoration: none;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  padding: 0.5em;
  transition-duration: 0.3s;
  border-radius: 6px;
}
.more a.arw {
  position: relative;
  padding: 0.5em 1em;
}
.more a.arw:after {
  position: absolute;
  display: block;
  content: "";
  width: 23px;
  height: 16px;
  background: url(../img/arw_1.png) no-repeat center top;
  top: 0;
  right: 5%;
  bottom: 0;
  left: 0;
  margin: auto 0 auto auto;
  transition-duration: 0.3s;
}
.more a:hover {
  background-color: #333;
}
.more a:hover.arw:after {
  right: 3%;
}

.tbl_1 {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 8%;
}
.tbl_1 tr th,
.tbl_1 tr td {
  padding: 1.5em;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.35em;
}
.tbl_1 tr th {
  width: 25%;
  background-color: rgba(243, 152, 0, 0.7);
  vertical-align: middle;
  color: #fff;
  border-right: #fff solid 1px;
  border-bottom: #fff solid 1px;
}
.tbl_1 tr td {
  width: 75%;
  background-color: rgba(243, 152, 0, 0.05);
  text-align: left;
  border-bottom: rgba(243, 152, 0, 0.7) solid 1px;
}
@media screen and (max-width: 767px) {
  .tbl_1 tr th,
  .tbl_1 tr td {
    font-size: 13px;
    font-size: 1.3rem;
    padding: 1em 0.3em;
  }
  .tbl_1 tr th {
    width: 25%;
  }
  .tbl_1 tr td {
    width: 75%;
    word-break: break-all;
  }
}

dl.faq {
  line-height: 1.65em;
  margin-bottom: 2%;
}
dl.faq dt {
  background-color: rgba(243, 152, 0, 0.7);
  padding: 0.8em 3em 0.8em 0.8em;
  font-size: 18px;
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 1em;
  position: relative;
}
dl.faq dt::before, dl.faq dt::after {
  position: absolute;
  display: block;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 3px;
  top: -21px;
  right: -2px;
  bottom: 0;
  left: 0;
  margin: auto 0 auto auto;
  transition-duration: 0.5s;
}
dl.faq dt::after {
  transform: rotate(90deg);
}
dl.faq dt.active::after {
  transform: rotate(0);
}
dl.faq dd {
  display: none;
  margin-top: -1em;
  margin-bottom: 2em;
  padding: 1.2em;
  background-color: rgba(243, 152, 0, 0.05);
  border-radius: 0 0 10px 10px;
}
dl.faq dd.active {
  display: block;
}
@media screen and (max-width: 767px) {
  dl.faq dt::before, dl.faq dt::after {
    right: 10px;
  }
}

.hl_box {
  padding: 1.2em 1.5em;
  margin-bottom: 2em;
  overflow: hidden;
  position: relative;
}
.hl_box:nth-child(odd), .hl_box.ext {
  background-color: rgba(243, 152, 0, 0.05);
}
.hl_box.ext {
  background-color: #fff;
}
.hl_box::before, .hl_box::after {
  position: absolute;
  display: block;
  content: "";
  width: 1em;
  height: 1em;
  background-color: #f39800;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: rotate(45deg);
}
.hl_box::before {
  margin: -0.5em auto auto -0.5em;
}
.hl_box::after {
  margin: auto -0.5em -0.5em auto;
}

.map_wide {
  position: relative;
  min-height: 450px;
}
.map_wide #map_canvas,
.map_wide iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.map_wide img {
  max-width: none;
}

.w2 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w3 {
  width: 30%;
}

.w35 {
  width: 35%;
}

.w4 {
  width: 40%;
}

.w45 {
  width: 45%;
}

.w5 {
  width: 50%;
}

.w55 {
  width: 55%;
}

.w6 {
  width: 60%;
}

.w65 {
  width: 65%;
}

.w7 {
  width: 70%;
}

.w75 {
  width: 75%;
}

.w8 {
  width: 80%;
}

.w85 {
  width: 85%;
}

.w9 {
  width: 90%;
}

.w95 {
  width: 95%;
}

.w33 {
  width: 33%;
}

.f_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.f_box.gtr .w2 {
  width: 18%;
}
.f_box.gtr .w3 {
  width: 28%;
}
.f_box.gtr .w4 {
  width: 38%;
}
.f_box.gtr .w5 {
  width: 48%;
}
.f_box.gtr .w6 {
  width: 58%;
}
.f_box.gtr .w7 {
  width: 68%;
}
.f_box.gtr .w8 {
  width: 78%;
}
.f_box.gtr .w9 {
  width: 88%;
}
.f_box.gtr .w25 {
  width: 23%;
}
.f_box.gtr .w35 {
  width: 33%;
}
.f_box.gtr .w45 {
  width: 43%;
}
.f_box.gtr .w55 {
  width: 53%;
}
.f_box.gtr .w65 {
  width: 63%;
}
.f_box.gtr .w75 {
  width: 73%;
}
.f_box.gtr .w85 {
  width: 83%;
}
.f_box.gtr .w95 {
  width: 93%;
}
.f_box.gtr .w33 {
  width: 31%;
}
.f_box.flw {
  flex-flow: wrap;
}
.f_box.r_rvs {
  flex-flow: row-reverse;
}
.f_box.w_rvs {
  flex-flow: wrap-reverse;
}
@media screen and (max-width: 767px) {
  .f_box {
    flex-flow: column;
  }
  .f_box.gtr .w2,
  .f_box.gtr .w3,
  .f_box.gtr .w4,
  .f_box.gtr .w5,
  .f_box.gtr .w6,
  .f_box.gtr .w7,
  .f_box.gtr .w8,
  .f_box.gtr .w9,
  .f_box.gtr .w25,
  .f_box.gtr .w35,
  .f_box.gtr .w45,
  .f_box.gtr .w55,
  .f_box.gtr .w65,
  .f_box.gtr .w75,
  .f_box.gtr .w85,
  .f_box.gtr .w95,
  .f_box.gtr .w33 {
    width: 100%;
    margin-bottom: 1em;
  }
  .f_box.flw {
    flex-flow: wrap;
  }
  .f_box.r_rvs {
    flex-flow: column;
  }
  .f_box.w_rvs {
    flex-flow: column;
  }
}

.about h1 {
  background: url(../img/h1_about.jpg) no-repeat center top;
}
.about h1 span::after {
  content: "About";
}
.about h2 {
  text-align: center;
  font-size: 48px;
  color: #f39800;
}
.about h2 span {
  font-size: 18px;
  letter-spacing: 0.1em;
  font-weight: 400;
  padding-bottom: 0.6em;
}

.abt_1 {
  background: url(../img/bg_about_1.jpg) no-repeat center top;
  padding-top: 10px;
}
.abt_1 p {
  font-size: 18px;
}

.abt_box_1 {
  background: url(../img/about_01.png) no-repeat center bottom;
  height: 600px;
  width: 100%;
  position: relative;
}
.abt_box_1 .txt_1, .abt_box_1 .txt_2, .abt_box_1 .txt_3, .abt_box_1 .txt_4, .abt_box_1 .txt_5 {
  position: absolute;
}
.abt_box_1 .txt_1 p, .abt_box_1 .txt_2 p, .abt_box_1 .txt_3 p, .abt_box_1 .txt_4 p, .abt_box_1 .txt_5 p {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  line-height: 1.3em;
}
.abt_box_1 .txt_1 {
  background: url(../img/about_05.png) no-repeat center bottom;
  top: 60%;
  left: 0;
  padding: 81px 30px 45px 30px;
}
.abt_box_1 .txt_2 {
  background: url(../img/about_03.png) no-repeat center bottom;
  top: 14%;
  left: 0.5%;
  padding: 60px 55px 80px 55px;
}
.abt_box_1 .txt_3 {
  background: url(../img/about_02.png) no-repeat center bottom;
  top: 2%;
  left: 27%;
  padding: 29px 58px 41px 52px;
}
.abt_box_1 .txt_4 {
  background: url(../img/about_04.png) no-repeat center bottom;
  top: 1%;
  left: 64%;
  padding: 60px 55px 80px 55px;
}
.abt_box_1 .txt_5 {
  background: url(../img/about_06.png) no-repeat center bottom;
  top: 58%;
  left: 67%;
  padding: 65px 30px 52px 30px;
}

.abt_2 figure {
  margin-bottom: 20px;
}
.abt_2 p {
  font-size: 18px;
  text-align: center;
  margin-bottom: 7%;
}
.abt_2 p.t_r {
  text-align: right;
}
.abt_2 p.ex_lrg span {
  color: #f39800;
  font-size: 30px;
  font-weight: 500;
}
.abt_2 .w8 h3.fukidashi {
  background: url(../img/about_10.png) no-repeat center top;
  padding: 44px 1em;
  text-align: center;
  margin-bottom: 0.8em;
}
.abt_2 .w8 p {
  text-align: left;
  padding-left: 4em;
}

.abt_box_2 {
  background-color: #f4f4f4;
  padding: 4%;
  margin-bottom: 5%;
}
.abt_box_2 h3 {
  font-size: 36px;
  display: block;
  margin: 0 auto 1.5em;
  text-align: center;
}
.abt_box_2 h3 em {
  font-style: normal;
  padding-bottom: 14px;
  border-bottom: #f39800 solid 3px;
}
.abt_box_2 h3 span {
  color: #f39800;
}
.abt_box_2 h3.ul em {
  display: inline-block;
}
.abt_box_2 .ul_x li {
  font-weight: 500;
}
.abt_box_2 .ul_chk li {
  font-size: 18px;
  font-weight: 500;
}
.abt_box_2.ext {
  padding-bottom: 7%;
  margin-bottom: 8%;
}
.abt_box_2.ext h3 {
  text-align: left;
}
.abt_box_2.ext .w35.lrg {
  position: relative;
}
.abt_box_2.ext .w35.lrg img {
  position: absolute;
  max-width: 115%;
  top: -150%;
  left: -10%;
}

.abt_box_3 {
  border: #f4f4f4 solid 20px;
  padding: 4%;
}
.abt_box_3 h3 {
  font-size: 30px;
}
.abt_box_3 h3 span {
  font-size: 36px;
}
.abt_box_3 h3 em {
  font-style: normal;
  font-size: 60px;
  color: #f39800;
  border-bottom: #f39800 double 10px;
}
.abt_box_3 p {
  margin-bottom: 0;
  text-align: left;
}

.abt_3 h3 {
  font-size: 36px;
  display: block;
  margin: 0 auto 1.5em;
  text-align: center;
}
.abt_3 h3 em {
  font-style: normal;
  padding-bottom: 14px;
  border-bottom: #f39800 solid 3px;
}
.abt_3 h3 span {
  color: #f39800;
}
.abt_3 h3.ul em {
  display: inline-block;
}
.abt_3 p {
  font-size: 18px;
  margin-bottom: 7%;
}
.abt_3 p + p {
  margin-top: -4%;
}

.abt_4 {
  background-color: #f4f4f4;
}
.abt_4 h2 {
  margin-bottom: 0.75em;
}

.abt_box_4 {
  background-color: #fff;
  padding: 5%;
  margin-bottom: 7%;
}
.abt_box_4::after {
  content: "";
  display: block;
  clear: both;
}
.abt_box_4 .f_r {
  margin: 0 0 6% 6%;
}
.abt_box_4 dl {
  width: auto;
}
.abt_box_4 dl dt {
  display: table;
  overflow: hidden;
  font-size: 22px;
  font-weight: 500;
  color: #f39800;
  position: relative;
  padding: 0.5em 0 1em;
}
.abt_box_4 dl dt::after {
  position: relative;
  content: "";
  display: inline-block;
  width: 148px;
  height: 1px;
  background-color: #f39800;
  margin: 0 0 0.4em 15px;
}
.abt_box_4 dl dd {
  line-height: 1.65em;
  margin-bottom: 2em;
}
.abt_box_4 dl dd p {
  margin-bottom: 1em;
  font-size: 18px;
}
.abt_box_4 dl dd p strong {
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .about h1 {
    background-size: auto 100%;
  }
  .about h2 {
    font-size: 5.7vw;
  }
  .about h2 span {
    font-size: 3.4vw;
  }
  .abt_1 {
    background-size: auto 100%;
  }
  .abt_1 p {
    font-size: 4.3vw;
  }
  .abt_box_1 {
    background-size: 48% auto;
    height: 80vw;
  }
  .abt_box_1 .txt_1 p, .abt_box_1 .txt_2 p, .abt_box_1 .txt_3 p, .abt_box_1 .txt_4 p, .abt_box_1 .txt_5 p {
    font-size: 2.7vw;
  }
  .abt_box_1 .txt_1 {
    background-size: 100% auto;
    top: 66%;
    left: 0;
    padding: 9% 4% 7% 4%;
  }
  .abt_box_1 .txt_2 {
    background-size: 100% auto;
    top: 15%;
    left: 0;
    padding: 6% 4% 9% 5%;
  }
  .abt_box_1 .txt_3 {
    background-size: 100% auto;
    top: 1%;
    left: 30%;
    padding: 4% 7% 5% 6%;
  }
  .abt_box_1 .txt_4 {
    background-size: 100% auto;
    top: 13%;
    left: 60%;
    padding: 7% 2% 8% 4%;
    width: 40%;
  }
  .abt_box_1 .txt_5 {
    background-size: 100% auto;
    top: 58%;
    left: 60%;
    padding: 10% 2% 6% 3%;
    width: 40%;
  }
  .abt_2 p {
    font-size: 4.3vw;
    margin-bottom: 7%;
  }
  .abt_2 p.ex_lrg span {
    font-size: 6.3vw;
  }
  .abt_2 .w8 h3.fukidashi {
    background-size: 100% 100%;
    padding: 5% 5% 8%;
  }
  .abt_2 .w8 p {
    padding: 0 1em;
  }
  .abt_box_2 {
    padding: 4%;
    margin-bottom: 5%;
  }
  .abt_box_2 h3 span {
    font-size: 4.7vw;
  }
  .abt_box_2 h3 em {
    line-height: 1.65em;
    display: block;
    font-size: 4.7vw;
    padding-bottom: 0.3em;
    border-bottom: #f39800 solid 2px;
  }
  .abt_box_2 .ul_x li {
    font-size: 4.3vw;
    margin-bottom: 1em;
  }
  .abt_box_2 figure.w35 img {
    width: 80%;
  }
  .abt_box_2 .ul_chk li {
    font-size: 4.3vw;
    padding: 0.2em 0 0.3em 1.7em;
  }
  .abt_box_2.ext {
    padding-bottom: 7%;
    margin-bottom: 8%;
  }
  .abt_box_2.ext h3 {
    text-align: left;
  }
  .abt_box_2.ext h3.ul {
    text-align: center;
  }
  .abt_box_2.ext .w35.lrg img {
    position: relative;
    max-width: 100%;
    top: 0;
    left: 0;
    margin-bottom: -15%;
  }
  .abt_box_3 {
    border: #f4f4f4 solid 10px;
  }
  .abt_box_3 h3 {
    font-size: 5.4vw;
    line-height: 1.8em;
  }
  .abt_box_3 h3 span {
    font-size: 120%;
  }
  .abt_box_3 h3 em {
    font-size: 200%;
    border-bottom: #f39800 double 6px;
  }
  .abt_3 h3 span {
    font-size: 4.7vw;
  }
  .abt_3 h3 em {
    line-height: 1.65em;
    display: block;
    font-size: 4.7vw;
    padding-bottom: 0.3em;
    border-bottom: #f39800 solid 2px;
  }
  .abt_3 h3.ul em {
    display: inline-block;
  }
  .abt_3 p {
    font-size: 4.3vw;
  }
  .abt_box_4 {
    background-color: #fff;
    padding: 5%;
    margin-bottom: 7%;
  }
  .abt_box_4 .f_r {
    margin: 0 auto 6%;
  }
  .abt_box_4 .f_r img {
    width: 70%;
  }
  .abt_box_4 dl dt {
    font-size: 5.3vw;
    padding: 0.5em 0 1em;
  }
  .abt_box_4 dl dt::after {
    width: 40vw;
    margin: 0 0 0.4em 15px;
  }
  .abt_box_4 dl dd p {
    font-size: 4.3vw;
  }
}
.contact h1 {
  background: url(../img/h1_about.jpg) no-repeat center top;
}
.contact h1 span::after {
  content: "Contact";
}

.sec_contact {
  padding-bottom: 6%;
}
.sec_contact h2 {
  color: #f89503;
}
.sec_contact .form_box {
  background-color: #e8e8e8;
  padding: 6% 8% 6% 12%;
  margin-bottom: 2%;
  border-radius: 3px;
}
.sec_contact .form_box dl {
  display: flex;
  flex-flow: wrap;
  align-items: center;
}
.sec_contact .form_box dl dt, .sec_contact .form_box dl dd {
  padding: 2% 0;
  line-height: 1.2em;
}
.sec_contact .form_box dl dt {
  width: 30%;
  font-weight: 700;
  font-size: 20px;
}
.sec_contact .form_box dl dt span {
  display: inline-block;
  color: #fff;
  background-color: #000;
  border-radius: 10px;
  padding: 0.2em 1em;
  margin-top: 5px;
}
.sec_contact .form_box dl dd {
  width: 70%;
  font-weight: 700;
}
.sec_contact .form_box dl dd input, .sec_contact .form_box dl dd select, .sec_contact .form_box dl dd .wpcf7-list-item-label {
  font-size: 20px;
  line-height: 1.4em;
  font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック体", "Yu Gothic", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic";
  padding: 0.5em;
  width: 93.5%;
}
.sec_contact .form_box dl dd input, .sec_contact .form_box dl dd select {
  border: #999 solid 1px;
  border-radius: 2px;
}
.sec_contact .form_box dl dd select {
  padding: 0.56em;
  margin-left: 3%;
}
.sec_contact .form_box dl dd input[type=date], .sec_contact .form_box dl dd select {
  width: 45%;
}
.sec_contact .form_box dl dd input[type=number] {
  width: 20%;
  margin-right: 0.5em;
}
.sec_contact .form_box dl dd input[type=radio], .sec_contact .form_box dl dd input[type=checkbox] {
  display: inline-block;
  width: auto;
}
.sec_contact .form_box dl dd input[type=checkbox] + span {
  padding: 0.5em 1.5em 0.5em 0.1em;
}
.sec_contact .form_box dl dd.name span input {
  width: 45%;
}
.sec_contact .form_box dl dd.name span:nth-of-type(2) input {
  margin-left: 3%;
}
.sec_contact .form_box .box_1 {
  background-color: #f2f2f2;
  padding: 3% 5%;
  border-radius: 20px;
  margin: 5% auto 0;
  font-weight: 700;
}
.sec_contact .form_box .box_1 p.ttl {
  text-align: center;
}
.sec_contact .form_box .box_1 ul {
  padding-left: 5%;
  margin-bottom: 5%;
}
.sec_contact .form_box .box_1 ul li {
  font-size: 20px;
  list-style: disc;
  margin-bottom: 0.5em;
}
.sec_contact .form_box .wpcf7-acceptance {
  display: block;
  text-align: center;
  font-size: 20px;
}
.sec_contact .form_box input[type=submit] {
  background-color: #f39800;
  color: #fff;
  display: block;
  margin: 5% auto 0;
  font-size: 24px;
  padding: 1em 4em;
  border: none;
  line-height: 1;
  font-weight: 700;
  border-radius: 20px;
}
.sec_contact p.end_txt {
  font-weight: 700;
  text-align: center;
  font-size: 18px;
}

.data h1 {
  background: url(../img/h1_data.jpg) no-repeat center top;
}
.data h1 span::after {
  content: "Data";
}
@media screen and (max-width: 768px) {
  .data h1 {
    background-size: cover;
  }
  .data h1 span::after {
    font-size: 17vw;
    bottom: 0;
  }
}

.dat_1 {
  background: url(../img/bg_dat_01.jpg) no-repeat center top;
  padding: 10px 0 3%;
}
.dat_1 .inner {
  margin-bottom: 3%;
}
.dat_1 .data_ttl {
  margin-bottom: 8%;
}
.dat_1 p {
  font-size: 18px;
  margin-bottom: 6%;
}
.dat_1 .f_box {
  margin-bottom: 6%;
}
.dat_1 .f_box .w5 {
  background-color: #fff;
  padding-bottom: 2.3em;
}
.dat_1 .f_box .w5 p {
  font-size: 14px;
  text-align: center;
  margin-bottom: 0;
}
.dat_1 .f_box .w5 p.ttl {
  text-align: center;
  margin-bottom: 0.5em;
}
.dat_1 .f_box .w5 p.ttl span {
  position: relative;
  background-color: #f39800;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  padding: 0.2em 1.2em;
}
.dat_1 .f_box .w5 p.num {
  font-size: 72px;
  font-weight: 700;
  text-align: center;
  margin-bottom: -0.15em;
}
.dat_1 .f_box .w5 p.num span {
  font-size: 30px;
}
.dat_1 .f_box.bnr {
  width: 30%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .dat_1 {
    background-size: cover;
  }
  .dat_1 p {
    font-size: 4.3vw;
  }
  .dat_1 .f_box .w5 {
    margin-top: 8%;
  }
  .dat_1 .f_box .w5 p {
    font-size: 3vw;
  }
  .dat_1 .f_box .w5 p.ttl span {
    font-size: 6.3vw;
  }
  .dat_1 .f_box .w5 p.num {
    font-size: 16vw;
  }
  .dat_1 .f_box.bnr {
    width: 90%;
    flex-flow: row;
    align-items: center;
    margin-bottom: 7%;
  }
  .dat_1 .f_box.bnr figure {
    margin: 0;
  }
}

.dat_2 {
  background-color: #fff;
  padding-bottom: 10%;
}
.dat_2 h2 {
  color: #f39800;
  font-size: 48px;
}
.dat_2 h2 span {
  font-weight: 400;
  padding: 0 0 0.7em;
  letter-spacing: 0.1em;
}
.dat_2 p {
  font-size: 18px;
}
.dat_2 .dat_box_1 {
  background-color: #f4f4f4;
  padding: 5%;
  margin-bottom: 5px;
}
.dat_2 .dat_box_1 .item {
  background-color: #fff;
  padding: 3%;
  margin-bottom: 5%;
}
.dat_2 .dat_box_1 .item h3 {
  font-size: 24px;
  color: #fff;
  background-color: #9e9d9d;
  padding: 0.4em;
  text-align: center;
  letter-spacing: 0.1em;
  font-weight: 500;
}
.dat_2 .dat_box_1 .item figure {
  margin-bottom: 6%;
}
.dat_2 .dat_box_1 .item figure:last-child {
  margin-bottom: 0;
}
.dat_2 .dat_box_1 .item:last-of-type {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .dat_2 h2 {
    font-size: 7.7vw;
  }
  .dat_2 p {
    font-size: 3.4vw;
  }
}

.flow h1 {
  background: url(../img/h1_flow.jpg) no-repeat center top;
}
.flow h1 span::after {
  content: "Flow";
}

.flw_1 {
  padding-top: 0;
  margin-bottom: 8.5%;
}
.flw_1 h2 {
  background-color: #f39800;
  color: #fff;
  margin-bottom: 0;
  font-size: 24px;
  letter-spacing: 0.1em;
  font-weight: 500;
  padding: 0.9em 0;
}
.flw_1 .flw_box_wrapper {
  padding: 20px 0 60px;
  position: relative;
}
.flw_1 .flw_box_wrapper::before {
  position: absolute;
  display: block;
  content: "";
  width: 2px;
  height: inherit;
  background-color: #f39800;
  top: 0;
  right: 0;
  bottom: 0;
  left: 6.6%;
  margin: 0 auto 0 0;
  z-index: 1;
}
.flw_1 .flw_box_wrapper .flw_box {
  padding: 40px 50px 70px 22px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.flw_1 .flw_box_wrapper .flw_box .txt_box {
  width: 51.5%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.flw_1 .flw_box_wrapper .flw_box .txt_box .step {
  text-align: center;
  border-top: #f39800 solid 2px;
  border-bottom: #f39800 solid 2px;
  background-color: #fff;
  color: #f39800;
  position: relative;
  z-index: 2;
  padding: 18px 0;
  line-height: 1;
  letter-spacing: 0.1em;
  font-size: 18px;
  width: 18.5%;
  font-weight: 500;
}
.flw_1 .flw_box_wrapper .flw_box .txt_box .step span {
  display: block;
  font-size: 200%;
  letter-spacing: 0;
  margin: 0 auto;
  text-align: center;
  padding-top: 3px;
}
.flw_1 .flw_box_wrapper .flw_box .txt_box .txt {
  width: 74.5%;
}
.flw_1 .flw_box_wrapper .flw_box .txt_box .txt h3 {
  font-size: 30px;
  margin-bottom: 1em;
}
.flw_1 .flw_box_wrapper .flw_box .txt_box .txt p {
  font-size: 18px;
}
.flw_1 .flw_box_wrapper .flw_box figure {
  width: 48%;
}
.flw_1 .flw_box_wrapper .flw_box:nth-of-type(even) {
  background-color: #f4f4f4;
}
.flw_1 .flw_box_wrapper .flw_box:nth-of-type(even) .txt_box .step {
  background-color: #f4f4f4;
}
.flw_1 .flw_box_wrapper .flw_box::before {
  position: absolute;
  display: block;
  content: "";
  width: 2px;
  height: inherit;
  background-color: #f39800;
  top: 0;
  right: 0;
  bottom: 0;
  left: 6.6%;
  margin: 0 auto 0 0;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .flow h1 {
    background-size: auto 100%;
  }
  .flw_1 h2 {
    font-size: 6.3vw;
  }
  .flw_1 .flw_box_wrapper::before {
    left: 8%;
  }
  .flw_1 .flw_box_wrapper .flw_box {
    flex-flow: column;
    padding: 4vw 5vw 7vw 2vw;
  }
  .flw_1 .flw_box_wrapper .flw_box .txt_box {
    width: 100%;
  }
  .flw_1 .flw_box_wrapper .flw_box .txt_box .step {
    font-size: 3.7vw;
    padding: 1em 0;
    width: 14%;
  }
  .flw_1 .flw_box_wrapper .flw_box .txt_box .txt {
    width: 80%;
    margin-bottom: 8%;
  }
  .flw_1 .flw_box_wrapper .flw_box .txt_box .txt h3 {
    font-size: 6.3vw;
    margin-bottom: 0.5em;
  }
  .flw_1 .flw_box_wrapper .flw_box .txt_box .txt p {
    font-size: 4.3vw;
  }
  .flw_1 .flw_box_wrapper .flw_box figure {
    width: 80%;
    margin-right: 0;
  }
  .flw_1 .flw_box_wrapper .flw_box::before {
    left: 8%;
  }
}
footer {
  text-align: center;
  background-color: #9e9d9d;
  line-height: 1.65em;
  color: #fff;
  padding: 2.65% 0 7%;
}
footer .f_box {
  width: 100%;
  max-width: 1024px;
  padding: 0 12px;
  margin: 0 auto 2.65%;
}
footer .w5 figure {
  margin-bottom: 3%;
  text-align: left;
}
footer .w5 p {
  text-align: left;
  line-height: 1.75;
}
footer .f_nav {
  margin-left: 8%;
}
footer .f_nav ul {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  max-height: 15em;
}
footer .f_nav ul li {
  width: 50%;
  line-height: 1;
  padding: 0;
  margin-top: 1.25em;
}
footer .f_nav ul li a {
  display: block;
  padding: 0;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  text-align: left;
}
footer .sns a {
  color: #fff;
  padding: 0 0.2em;
}
@media screen and (max-width: 1299px) {
  footer .f_nav ul {
    max-height: none;
  }
}
@media screen and (max-width: 767px) {
  footer {
    padding-bottom: 20%;
  }
  footer .f_nav {
    padding: 0;
    margin: 0;
  }
  footer .f_nav ul {
    max-height: none;
  }
  footer .f_nav ul li {
    width: 100%;
  }
  footer .f_nav ul li a {
    display: block;
    padding: 0.25em;
    font-size: clamp(14px, 4.3vw, 18px);
  }
  footer .f_nav ul li:not(:first-child) {
    border-left: none;
  }
  footer .copyright {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.wpcf7-form input[type=submit] {
  width: 100%;
  max-width: 280px;
  font-size: 20px;
  font-size: 2rem;
  text-align: center;
  padding: 1em;
  display: block;
  margin: 0 auto;
}

.form_dl {
  display: flex;
  flex-flow: wrap;
  border-top: #ccc solid 1px;
  margin-bottom: 5%;
}
.form_dl dt, .form_dl dd {
  border-bottom: #ccc solid 1px;
}
.form_dl dt input, .form_dl dt textarea, .form_dl dd input, .form_dl dd textarea {
  padding: 0.8em;
  background-color: #fcfcfc;
  border: #eee solid 1px;
  width: 100%;
}
.form_dl dt.zip input, .form_dl dt.tel input, .form_dl dd.zip input, .form_dl dd.tel input {
  width: 40%;
}
.form_dl dt {
  width: 30%;
  position: relative;
  padding: 1.8em 1em;
}
.form_dl dt span {
  background-color: #f39800;
  padding: 0.3em 0.2em 0.2em;
  position: absolute;
  top: 1.5em;
  right: 1em;
  color: #fff;
}
.form_dl dt span.fre {
  background-color: #ccc;
  color: #000;
}
.form_dl dd {
  padding: 1em;
  width: 70%;
}

header .inner {
  padding: 0 0.5% 0 1%;
  position: relative;
  display: flex;
  height: 102px;
  align-items: center;
}
header .logo {
  width: 248px;
  height: 64px;
  margin-bottom: -8px;
}
header .h_info p.more a {
  width: 300px;
  max-width: none;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f39800;
  border-radius: 6px;
  font-size: 20px;
  font-size: 2rem;
  font-weight: 800;
}
header .h_info p.more a img {
  margin-right: 1.6rem;
}
@media screen and (max-width: 1470px) {
  header .logo {
    width: 16%;
    height: auto;
    margin-bottom: -8px;
  }
  header .logo img {
    width: 100%;
  }
  header .h_info p.more a {
    width: auto;
    max-width: 300px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1024px) {
  header .inner {
    height: 60px;
    padding: 0 5% 0 3%;
  }
  header .logo {
    width: 150px;
    height: auto;
    margin-bottom: -9px;
  }
  header .logo img {
    width: 100%;
  }
  header .h_info {
    display: none;
  }
}

.g_nav {
  margin-left: auto;
  transition-duration: 0.3s;
}
.g_nav ul {
  display: flex;
  align-items: center;
  padding-top: 5px;
}
.g_nav ul li {
  margin-right: 37px;
  line-height: 1;
}
.g_nav ul li a {
  position: relative;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  font-size: 2rem;
}
.g_nav ul li a span {
  display: block;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 400;
  color: #f39800;
  padding-top: 6px;
}
.g_nav ul li:last-child {
  margin-right: 48px;
}
@media screen and (max-width: 1470px) {
  .g_nav ul li {
    margin-right: 1.5em;
  }
  .g_nav ul li a {
    font-size: 16px;
  }
  .g_nav ul li a span {
    font-size: 12px;
  }
  .g_nav ul li:last-child {
    margin-right: 1em;
  }
}
@media screen and (max-width: 1024px) {
  .g_nav {
    display: none;
    position: absolute;
    top: 55px;
    right: 0;
    width: 100%;
    z-index: 10;
    max-width: 40vw;
  }
  .g_nav ul {
    flex-flow: column;
    width: 100%;
    max-width: 480px;
  }
  .g_nav ul li {
    margin: 0;
    display: block;
    width: 100%;
  }
  .g_nav ul li a {
    display: block;
    background-color: rgba(102, 102, 102, 0.9);
    color: #fff;
    width: 100%;
    padding: 1em;
    border-bottom: #fff solid 1px;
  }
  .g_nav ul li a::before {
    display: none;
  }
  .g_nav ul li:first-child a {
    padding-left: 1em;
  }
  .g_nav ul li:last-child {
    margin-right: 0;
  }
}

.sp_menu {
  display: none;
}
@media screen and (max-width: 1024px) {
  .sp_menu {
    display: block;
    width: 30px;
    height: 23px;
    margin-left: auto;
    position: relative;
  }
  .sp_menu span {
    display: block;
    background-color: #f39800;
    width: 30px;
    height: 3px;
    position: relative;
    transition-duration: 0.3s;
    border-radius: 2px;
  }
  .sp_menu span::before, .sp_menu span::after {
    position: absolute;
    display: block;
    content: "";
    width: inherit;
    height: inherit;
    background-color: inherit;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto auto auto;
  }
  .sp_menu span::before {
    top: 10px;
  }
  .sp_menu span::after {
    top: 20px;
  }
  .sp_menu.active span {
    transform: rotate(45deg);
    top: 8px;
  }
  .sp_menu.active span::before, .sp_menu.active span::after {
    transform: rotate(-90deg);
    top: 0;
    left: 0;
  }
}

.law h1 {
  background: url(../img/h1_about.jpg) no-repeat center top;
}
.law h1 span::after {
  content: "Specific Trade Law";
}
.law h2 {
  text-align: center;
  font-size: 48px;
  color: #f39800;
}
.law h2 span {
  font-size: 18px;
  letter-spacing: 0.1em;
  font-weight: 400;
  padding-bottom: 0.6em;
}

.law_1 {
  padding-top: 0;
}

@media screen and (max-width: 767px) {
  .law h1 {
    background-size: auto 100%;
  }
  .law h1 span::after {
    font-size: 14vw;
    bottom: 4%;
  }
  .law h2 {
    font-size: 5.7vw;
  }
  .law h2 span {
    font-size: 3.4vw;
  }
}
.news h1, .blog h1 {
  background: url(../img/h1_about.jpg) no-repeat center top;
}
.news .sec_article, .blog .sec_article {
  padding: 0 0 3%;
}

.news h1 span::after {
  content: "News";
}

.blog h1 span::after {
  content: "Blog";
}

.plan h1 {
  background: url(../img/h1_plan.jpg) no-repeat center top;
}
.plan h1 span::after {
  content: "Plan";
}
@media screen and (max-width: 768px) {
  .plan h1 {
    background-size: cover;
  }
}

.pln_1 {
  padding-top: 0;
}

.p_box_1 {
  position: relative;
  display: flex;
  margin-bottom: 8%;
}
.p_box_1 figure {
  width: 50%;
  position: relative;
  z-index: 1;
}
.p_box_1 figure img {
  max-width: 110%;
  width: 110%;
}
.p_box_1 .txt {
  width: 50%;
  background-color: #f4f4f4;
  padding: 3% 2% 3% 2.6%;
  margin-top: 6%;
  position: relative;
  z-index: 2;
}
.p_box_1 .txt h3 {
  font-weight: 900;
  color: #f39800;
}
.p_box_1 .txt p {
  font-size: 20px;
  font-weight: 600;
}
.p_box_1:nth-child(even) {
  flex-flow: row-reverse;
}
.p_box_1:nth-child(even) figure img {
  margin-left: -10%;
}
@media screen and (max-width: 768px) {
  .p_box_1 {
    flex-flow: column;
  }
  .p_box_1 figure {
    width: 95%;
    margin-left: 0;
    margin-bottom: 0;
  }
  .p_box_1 figure img {
    max-width: 100%;
    margin: auto;
  }
  .p_box_1 .txt {
    width: 95%;
    margin: -10% 0 0 auto;
  }
  .p_box_1 .txt p {
    font-size: 4.7vw;
  }
  .p_box_1:nth-child(even) {
    flex-flow: column;
  }
  .p_box_1:nth-child(even) figure {
    margin-left: 0;
    margin-bottom: 0;
  }
  .p_box_1:nth-child(even) figure img {
    margin: auto;
  }
  .p_box_1:nth-child(even) .txt {
    margin: -10% 0 0 auto;
  }
}

.pln_2 {
  background: url(../img/bg_pln_1.jpg) no-repeat center top;
  padding-bottom: 8%;
}
.pln_2 h2 {
  font-size: 42px;
  line-height: 1.5em;
}
.pln_2 h2 em {
  font-style: normal;
  padding-bottom: 7px;
  border-bottom: #f39800 solid 3px;
  display: inline;
}
.pln_2 h2 span {
  color: #f39800;
  font-size: 42px;
  display: inline-block;
}
.pln_2 .f_box {
  align-items: center;
}
.pln_2 .f_box figure {
  width: 44%;
  margin-bottom: 0;
}
.pln_2 .f_box figure.arw {
  width: 10%;
}
.pln_2 .f_box figure figcaption {
  display: block;
  text-align: left;
  color: #f39800;
  font-size: 24px;
  padding: 1em 0 0 0.5em;
}
.pln_2 p {
  text-align: center;
  font-size: 20px;
}
.pln_2 p strong {
  font-size: 36px;
  color: #f39800;
}
@media screen and (max-width: 768px) {
  .pln_2 {
    background-size: cover;
  }
  .pln_2 h2 {
    font-size: 7.3vw;
    line-height: 1.65em;
  }
  .pln_2 h2 span {
    font-size: 7.3vw;
    padding-bottom: 0.6em;
  }
  .pln_2 h2 em {
    border-width: 2px;
    padding-bottom: 0.2em;
  }
  .pln_2 p {
    font-size: 4.7vw;
  }
  .pln_2 p strong {
    font-size: 6.7vw;
  }
  .pln_2 .f_box figure {
    width: 80%;
  }
  .pln_2 .f_box figure.arw {
    width: 100%;
    margin: 5% auto;
  }
  .pln_2 .f_box figure.arw img {
    transform: rotate(90deg);
    width: 6%;
  }
  .pln_2 .f_box figure figcaption {
    font-size: 4.7vw;
  }
}

.pln_3 {
  background-color: #f4f4f4;
  padding-bottom: 8%;
}
.pln_3 h2 {
  font-size: 48px;
  color: #f39800;
}
.pln_3 h2 span {
  display: block;
  letter-spacing: 0.1em;
  font-weight: 500;
  padding-bottom: 0.8em;
}
.pln_3 p {
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .pln_3 h2 {
    font-size: 30px;
  }
  .pln_3 p {
    font-size: 4.7vw;
  }
}

.p_box_2 {
  background-color: #fff;
  padding: 4%;
}
.p_box_2 h3 {
  background-color: #f39800;
  font-size: 36px;
  text-align: center;
  padding: 0.5em;
  color: #fff;
  margin-bottom: 0.5em;
}
.p_box_2 table {
  width: 100%;
  border-collapse: collapse;
}
.p_box_2 table th, .p_box_2 table td {
  border: #999 solid 1px;
  padding: 1em;
  vertical-align: middle;
}
.p_box_2 table th {
  width: 45%;
  font-size: 20px;
  background-color: #f4f4f4;
  font-weight: 400;
}
.p_box_2 table td {
  width: 55%;
  text-align: right;
  font-size: 24px;
  font-weight: 600;
}
.p_box_2 ul {
  margin-bottom: 2em;
}
.p_box_2 ul li {
  position: relative;
  padding-left: 1em;
  font-size: 20px;
  line-height: 1.75em;
}
.p_box_2 ul li::before {
  position: absolute;
  content: "●";
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto auto auto 0;
}
.p_box_2 + .p_box_2 {
  margin-top: 6%;
}
@media screen and (max-width: 768px) {
  .p_box_2 h3 {
    font-size: 7.3vw;
  }
  .p_box_2 table th {
    font-size: 4.7vw;
  }
  .p_box_2 table td {
    font-size: 5.6vw;
  }
  .p_box_2 ul li {
    font-size: 4.7vw;
  }
}

.p_box_3 {
  border: #f39800 solid 4px;
  padding: 0 10% 3%;
  margin-top: 15%;
}
.p_box_3 h3 {
  position: relative;
  text-align: center;
  margin-bottom: -20px;
  padding: 0;
}
.p_box_3 h3 span {
  position: relative;
  display: inline-block;
  padding: 0.7em 1.7em;
  background-color: #f39800;
  color: #fff;
  font-size: 30px;
  top: -42px;
}
.p_box_3 ul li {
  position: relative;
  padding-left: 1em;
  font-size: 20px;
  line-height: 1.75em;
}
.p_box_3 ul li::before {
  position: absolute;
  content: "●";
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto auto auto 0;
}
@media screen and (max-width: 768px) {
  .p_box_3 {
    padding: 0 6% 6%;
  }
  .p_box_3 h3 {
    margin-bottom: -1em;
  }
  .p_box_3 h3 span {
    top: -9vw;
  }
  .p_box_3 ul li {
    font-size: 4.7vw;
  }
}

.pln_4 {
  background: url(../img/bg_pln_2.jpg) no-repeat center top;
  padding: 4% 0;
}
.pln_4 h2 {
  color: #fff;
  text-align: center;
  margin-bottom: 1.63em;
  border: none;
}
.pln_4 h2 span {
  position: relative;
  font-size: 36px;
  display: inline-block;
  padding: 0.3em 0;
  line-height: 1.2em;
}
.pln_4 h2 span::before, .pln_4 h2 span::after {
  position: absolute;
  display: block;
  content: "";
  width: 2px;
  height: 2.3em;
  background-color: #fff;
  top: 0.3em;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.pln_4 h2 span::before {
  margin-left: -1em;
  transform: rotate(-30deg);
}
.pln_4 h2 span::after {
  margin-right: -1em;
  transform: rotate(30deg);
}
.pln_4 h2 em {
  font-size: 30px;
  font-style: normal;
}
.pln_4 h2::after {
  display: none;
}
.pln_4 .f_box {
  margin-bottom: 1.5em;
}
.pln_4 .f_box .w5 {
  padding: 0 3% 3%;
  background-color: #fff;
}
.pln_4 .f_box .w5 p {
  text-align: center;
  font-weight: 500;
}
.pln_4 .f_box .w5 p.ttl {
  position: relative;
  margin-bottom: -2.5em;
}
.pln_4 .f_box .w5 p.ttl span {
  position: relative;
  font-weight: 700;
  font-size: 30px;
  color: #fff;
  padding: 0.7em 1.7em;
  background-color: #f39800;
  display: inline-block;
  top: -20px;
}
.pln_4 .f_box .w5 h3 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 0.2em;
}
.pln_4 .f_box .w5 figure {
  margin-bottom: 0.4em;
}
.pln_4 .f_box + p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 3em;
}
.pln_4 figure.price {
  background-color: #fff;
  padding: 3% 3% 5%;
}
@media screen and (max-width: 768px) {
  .pln_4 h2 span {
    font-size: 5.7vw;
  }
  .pln_4 h2 em {
    font-size: 5.3vw;
  }
  .pln_4 .f_box .w5 h3 {
    font-size: 6.3vw;
  }
  .pln_4 .f_box .w5 p.ttl span {
    font-size: 5.3vw;
  }
  .pln_4 .f_box .w5 + .w5 {
    margin-top: 10%;
  }
  .pln_4 .f_box + p {
    font-size: 4.7vw;
  }
}

.pln_5 {
  padding-bottom: 8%;
}
.pln_5 h3 {
  background-color: #f4f4f4;
  padding: 0.4em;
  text-align: center;
  margin-bottom: 0.5em;
}
.pln_5 ul {
  margin-bottom: 3em;
}
.pln_5 ul li {
  position: relative;
  padding-left: 1em;
  font-size: 20px;
  line-height: 1.75em;
}
.pln_5 ul li::before {
  position: absolute;
  content: "・";
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto auto auto 0;
}
.pln_5 p {
  font-size: 20px;
  margin-bottom: 3em;
}
.pln_5 p:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .pln_5 ul li {
    font-size: 4.7vw;
  }
  .pln_5 p {
    font-size: 4.7vw;
  }
}

.privacy h1 {
  background: url(../img/h1_about.jpg) no-repeat center top;
}
.privacy h1 span::after {
  content: "Privacy Policy";
}
@media screen and (max-width: 768px) {
  .privacy h1 {
    background-size: cover;
  }
  .privacy h1 span::after {
    font-size: 17vw;
    bottom: 0;
  }
}

.pp_1 {
  padding: 0 0 8%;
}
.pp_1 h2 {
  background-color: #f4f4f4;
  color: #000;
  padding: 0.4em;
  text-align: center;
  margin-bottom: 1em;
  font-size: 200%;
}
.pp_1 h3 {
  font-size: 24px;
  margin-bottom: 0;
}
.pp_1 ul {
  margin-bottom: 3em;
}
.pp_1 ul li {
  position: relative;
  padding-left: 1em;
  line-height: 1.75em;
}
.pp_1 ul li::before {
  position: absolute;
  content: "・";
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto auto auto 0;
}
.pp_1 p {
  margin-bottom: 2em;
}
.pp_1 p + p {
  margin-bottom: 1.5em;
}
.pp_1 p + ul {
  margin-top: -2em;
}
@media screen and (max-width: 768px) {
  .pp_1 h2 {
    font-size: 130%;
    line-height: 1.5em;
  }
  .pp_1 h3 {
    font-size: 130%;
    line-height: 1.5em;
  }
}

.sitemap h1 {
  background: url(../img/h1_about.jpg) no-repeat center top;
}
.sitemap h1 span::after {
  content: "Sitemap";
}
@media screen and (max-width: 768px) {
  .sitemap h1 {
    background-size: cover;
  }
  .sitemap h1 span::after {
    font-size: 17vw;
    bottom: 0;
  }
}

.sitemap_1 {
  padding: 3% 0 5%;
}

.sitemap_ul {
  margin-bottom: 30px;
  margin-left: 30px;
  line-height: 50px;
}
.sitemap_ul a {
  border-bottom: solid 1px;
}
.sitemap_ul li {
  list-style: disc;
  font-weight: bold;
  font-size: 30px;
}
@media screen and (min-width: 1300px) {
  .sitemap_ul {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    max-height: 20em;
  }
}
@media screen and (max-width: 767px) {
  .sitemap_ul li {
    font-size: 5.4vw;
  }
}

.voice h1 {
  background: url(../img/h1_voice.jpg) no-repeat center top;
}
.voice h1 span::after {
  content: "Voice";
}

.sec_vic_1 {
  padding-top: 0;
}
.sec_vic_1 .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto 5%;
  border: #f39800 solid 1px;
  padding: 4% 3%;
}
.sec_vic_1 .item figure {
  width: 27%;
  margin: auto auto auto 0;
}
.sec_vic_1 .item .txt_box {
  width: 70%;
}
.sec_vic_1 h2 {
  font-size: 30px;
  color: #f39800;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0.7em;
}
.sec_vic_1 h2 span {
  color: #1b1b1b;
  font-size: 22px;
  font-weight: 500;
  padding: 0 0 0.8em;
  display: inline-block;
}
.sec_vic_1 p {
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .voice h1 {
    background-size: auto 100%;
  }
  .sec_vic_1 .item {
    flex-flow: column;
    padding: 8% 3%;
  }
  .sec_vic_1 .item figure {
    width: 60%;
    margin: auto auto 5%;
  }
  .sec_vic_1 .item .txt_box {
    width: 100%;
  }
  .sec_vic_1 h2 {
    font-size: 5.3vw;
    margin-bottom: 0.7em;
  }
  .sec_vic_1 h2 span {
    font-size: 4.3vw;
    padding: 0 0 0.8em;
  }
  .sec_vic_1 p {
    font-size: 3.7vw;
  }
}
ul.ul_x li {
  font-size: 18px;
  padding-left: 1.7em;
  line-height: 1.333;
  background: url(../img/icon_x.png) no-repeat 0 0;
  margin-bottom: 0.6em;
}

ul.ul_chk {
  margin-top: -2em;
}
ul.ul_chk li {
  font-size: 20px;
  background: url(../img/icon_chk.png) no-repeat 0 0.3em;
  padding-left: 1.7em;
  margin-bottom: 0.5em;
  line-height: 1.4em;
}
ul.ul_chk li:last-child {
  margin-bottom: 0;
}

.mv {
  position: relative;
  padding-bottom: 180px;
}
.mv .mv_img {
  text-align: right;
  overflow: hidden;
  width: 100%;
}
.mv .mv_img img {
  margin: 0 auto;
}
.mv .catch {
  position: absolute;
  top: 22.4%;
  left: 14.9%;
}
.mv .catch p {
  text-shadow: 0 0 3px #fff, 0 0 4px #fff, 0 0 5px #fff, 0 0 5px #fff;
  font-weight: 700;
}
.mv .catch p.txt_1 {
  font-size: 30px;
  margin-bottom: 0.45em;
  letter-spacing: 0.1em;
}
.mv .catch p.txt_2 {
  font-size: 48px;
  line-height: 1.35em;
  margin: 0 0 0.25em -0.1em;
}
.mv .catch p.txt_2 span {
  color: #f39800;
  position: relative;
  font-size: 55px;
  left: -0.6em;
}
.mv .catch p.txt_3 {
  font-weight: 600;
  font-size: 24px;
  margin: 0 0 0 -0.5em;
  line-height: 1.75em;
}
.mv .catch p.txt_3 span {
  color: #f39800;
}
.mv .btn {
  position: fixed;
  top: 71%;
  right: 1%;
  z-index: 3;
}
.mv .btn .more {
  margin-bottom: 12px;
}
.mv .btn .more a {
  background-color: #35bc27;
  width: 295px;
  max-width: none;
  height: 100px;
  color: #fff;
  font-weight: 800;
  font-size: 24px;
  padding-top: 36px;
  border-radius: 6px;
}
.mv .btn .more a.inv {
  background-color: #f39800;
  padding-top: 23px;
}
.mv .txt {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto auto 10px;
  width: 700px;
  background-color: #fff;
  border: #999 solid 1px;
  box-shadow: 0 0 6px rgba(153, 153, 153, 0.6);
}
.mv .txt p.ttl {
  font-size: 48px;
  font-weight: 800;
  text-align: center;
  line-height: 1;
  padding: 3.8% 0 3.5%;
  margin-bottom: 0;
}
.mv .txt p.ttl span {
  font-size: 80px;
  color: #f39800;
}
.mv .txt p.ttl span.sml {
  font-size: 72px;
  position: relative;
  left: 5px;
}
.mv .txt p.ttl span.sml2 {
  font-size: 60px;
  color: #333;
  position: relative;
  left: 5px;
}
.mv .txt .box {
  background-color: #f4f4f4;
  padding: 2% 3% 3% 7.4%;
}
.mv .txt .box p {
  font-size: 14px;
  line-height: 1.75em;
}
@media screen and (max-width: 1024px) {
  .mv .mv_img img {
    width: 120%;
  }
  .mv .catch {
    top: 10%;
    left: 5%;
  }
  .mv .catch p.txt_1 {
    font-size: 3vw;
  }
  .mv .catch p.txt_2 {
    font-size: 3.8vw;
  }
  .mv .catch p.txt_2 span {
    font-size: 5vw;
  }
  .mv .catch p.txt_3 {
    font-size: 2.2vw;
  }
  .mv .btn {
    top: 79%;
  }
  .mv .btn .more a {
    font-size: 18px;
    width: 220px;
    height: 60px;
    padding-top: 16px;
    line-height: 1.4em;
  }
  .mv .btn .more a.inv {
    padding-top: 3px;
  }
}
@media screen and (max-width: 767px) {
  .mv {
    padding-bottom: 50vw;
  }
  .mv .mv_img {
    overflow: hidden;
    width: 100%;
  }
  .mv .mv_img img {
    margin-left: -40%;
    width: 170%;
  }
  .mv .catch {
    left: 5%;
    top: 8%;
  }
  .mv .catch p.txt_1 {
    font-size: 4.7vw;
    margin-left: -0.2em;
  }
  .mv .catch p.txt_2 {
    font-size: 5.3vw;
    margin-left: -0.3em;
    text-indent: -0.1em;
  }
  .mv .catch p.txt_2 span {
    font-size: 6.7vw;
    left: -0.4em;
  }
  .mv .catch p.txt_3 {
    font-size: 4.3vw;
    margin-left: -0.3em;
    text-indent: -0.4em;
  }
  .mv .btn {
    top: inherit;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto auto 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    height: 18.5vw;
    background-color: rgba(153, 153, 153, 0.6);
    width: 100vw;
    padding: 2vw;
  }
  .mv .btn .more {
    width: 49%;
  }
  .mv .btn .more a {
    top: 0;
    font-size: 4.3vw;
    width: 100%;
    height: 15vw;
    padding: 4.5vw 10vw 0 2vw;
    line-height: 1.4em;
  }
  .mv .btn .more a.inv {
    padding-top: 1vw;
  }
  .mv .txt {
    width: 94vw;
  }
  .mv .txt p.ttl {
    font-size: 6.3vw;
  }
  .mv .txt p.ttl span {
    font-size: 8.3vw;
  }
  .mv .txt p.ttl span.sml {
    font-size: 7.7vw;
  }
  .mv .txt p.ttl span.sml2 {
    font-size: 7.3vw;
  }
  .mv .txt .box {
    padding: 3%;
  }
  .mv .txt .box p {
    font-size: 3.4vw;
  }
}

.sec_top_1 {
  background: url(../img/bg_01.jpg) no-repeat center bottom;
  padding: 147px 0 50px;
}
.sec_top_1 h2 {
  background-color: #fff;
  margin-bottom: 96px;
  color: #333;
  text-shadow: 0px 0px 8px rgb(255, 255, 255), 0px 0px 8px rgb(255, 255, 255), 0px 0px 4px rgb(255, 255, 255), 0px 0px 4px rgb(255, 255, 255);
}
.sec_top_1 h2::after {
  content: "Can we stop now?";
  color: #f39800;
}
.sec_top_1 .f_box .w5 {
  background-color: #fff;
  padding: 4.7% 2.5% 2%;
  margin-bottom: 9%;
}
.sec_top_1 .f_box .w5 figure {
  margin-bottom: 12px;
}
.sec_top_1 .f_box .w5 h3 {
  text-align: center;
  font-weight: 800;
  font-size: clamp(24px, 3.7vw, 30px);
  border: none;
  background: url(../img/ttl_bg_2.png) no-repeat center bottom;
  padding-bottom: 0.75em;
}
@media screen and (max-width: 1024px) {
  .sec_top_1 {
    background-size: auto 80%;
  }
  .sec_top_1 h2::after {
    font-size: 14vw;
  }
}
@media screen and (max-width: 767px) {
  .sec_top_1 {
    background-repeat: repeat-y;
    background-size: 200% auto;
    padding-top: 0;
  }
  .sec_top_1 h2 {
    margin-bottom: 2em;
    padding: 1.5em 0 2em;
  }
  .sec_top_1 h2::after {
    font-size: clamp(80px, 22vw, 90px);
    bottom: 1.4em;
  }
  .sec_top_1 .f_box .w5 ul li {
    font-size: 4.7vw;
  }
  .sec_top_1 .f_box .w5 h3 {
    font-size: 6.4vw;
    background-size: 70%;
  }
}

.sec_top_2 {
  background-color: #9e9d9d;
  padding: 3.6% 0 3%;
}
.sec_top_2 h3 {
  color: #f39800;
  text-align: center;
  margin-bottom: 1.63em;
  border: none;
  text-shadow: 1px 1px 0 #FFF, -1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px -1px 0 #FFF, 0px 1px 0 #FFF, 0 -1px 0 #FFF, -1px 0 0 #FFF, 1px 0 0 #FFF;
}
.sec_top_2 h3 span {
  position: relative;
  font-size: 36px;
}
.sec_top_2 h3 span::before, .sec_top_2 h3 span::after {
  position: absolute;
  display: block;
  content: "";
  width: 2px;
  height: 2.3em;
  background-color: #fff;
  top: 0.3em;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.sec_top_2 h3 span::before {
  margin-left: -2em;
  transform: rotate(-30deg);
}
.sec_top_2 h3 span::after {
  margin-right: -2em;
  transform: rotate(30deg);
}
.sec_top_2 .f_box {
  align-items: center;
  margin-bottom: 7.5%;
}
.sec_top_2 .f_box .w5 p {
  font-size: 18px;
  color: #fff;
  line-height: 2;
}
.sec_top_2 .box_1 {
  background-color: #fff;
  padding: 3% 2.5%;
}
.sec_top_2 .box_1 p.ttl {
  text-align: center;
  margin-bottom: 0;
  position: relative;
  top: -3.4em;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto auto;
}
.sec_top_2 .box_1 p.ttl span {
  display: inline-block;
  padding: 2.4% 5%;
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  background-color: #f39800;
}
@media screen and (max-width: 1024px) {
  .sec_top_2 h3 {
    margin-bottom: 2%;
  }
  .sec_top_2 h3 span {
    font-size: clamp(20px, 3.7vw, 30px);
  }
  .sec_top_2 h3 span::before {
    margin-left: -1em;
  }
  .sec_top_2 h3 span::after {
    margin-right: -1em;
  }
  .sec_top_2 .box_1 p.ttl {
    top: -3.5em;
  }
  .sec_top_2 .box_1 p.ttl span {
    font-size: 3vw;
  }
}
@media screen and (max-width: 767px) {
  .sec_top_2 h3 {
    margin-bottom: 1.63em;
  }
  .sec_top_2 h3 span {
    font-size: 5.7vw;
  }
  .sec_top_2 h3 span::before {
    margin-left: -0.5em;
  }
  .sec_top_2 h3 span::after {
    margin-right: -2.5em;
  }
  .sec_top_2 .f_box .w5 p {
    font-size: 4.7vw;
  }
  .sec_top_2 .box_1 {
    padding: 5% 2.5%;
  }
  .sec_top_2 .box_1 p.ttl span {
    font-size: 5.7vw;
  }
  .sec_top_2 .box_1 ul li {
    font-size: 4.7vw;
    line-height: 1.4em;
  }
}

.sec_top_3 {
  background-color: #f4f4f4;
  padding: 7.25% 0 4%;
}
.sec_top_3 h2 {
  font-size: 38px;
  line-height: 1.333;
  letter-spacing: -0.04em;
  margin-bottom: 2em;
}
.sec_top_3 h2 span {
  font-size: 38px;
  color: #f39800;
  display: inline;
}
.sec_top_3 h2::after {
  content: "Reason";
  font-size: 160px;
  bottom: 0.4em;
}
.sec_top_3 .f_box .w25 {
  border: #f39800 solid 2px;
  padding: 0;
  background-color: #fff;
}
.sec_top_3 .f_box .w25 .num {
  position: relative;
  font-size: 36px;
  font-weight: 600;
  padding: 0.25em 0 0 0.55em;
  border-radius: 100%;
  color: #fff;
  background-color: #f39800;
  width: 1.7em;
  height: 1.7em;
  display: block;
  margin: 0 auto auto;
  top: -0.85em;
  margin-bottom: -0.2em;
}
.sec_top_3 .f_box .w25 figure {
  margin-bottom: 1.3em;
}
.sec_top_3 .f_box .w25 p {
  text-align: center;
  font-weight: 600;
  line-height: 1.333;
  font-size: 18px;
  margin-bottom: 1.3em;
}
@media screen and (max-width: 1024px) {
  .sec_top_3 h2 {
    font-size: 4.7vw;
  }
  .sec_top_3 h2::after {
    font-size: 13vw;
    bottom: 0.65em;
  }
  .sec_top_3 .f_box .w25 p {
    font-size: 1.7vw;
  }
}
@media screen and (max-width: 767px) {
  .sec_top_3 h2 {
    font-size: 5.7vw;
  }
  .sec_top_3 h2 span {
    font-size: 6.3vw;
  }
  .sec_top_3 h2::after {
    font-size: 25vw;
    bottom: 0.45em;
  }
  .sec_top_3 .f_box .w25 {
    width: 80%;
    margin: 5% auto;
  }
  .sec_top_3 .f_box .w25 p {
    font-size: 4.7vw;
  }
}

.sec_top_4 {
  padding: 4.3% 0 4%;
}
.sec_top_4 h3 {
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  line-height: 1.25;
  margin-bottom: 0.8em;
}
.sec_top_4 h3 span {
  color: #f39800;
}
.sec_top_4 h3 em {
  font-style: normal;
  border-bottom: #f39800 solid 4px;
  padding-bottom: 3px;
}
.sec_top_4 .f_box {
  max-width: 910px;
  margin: 0 auto 1.8em;
}
.sec_top_4 .f_box .w33 {
  border: #f39800 solid 4px;
}
.sec_top_4 .f_box .w33 p {
  font-size: 24px;
  font-weight: 700;
  color: #f39800;
  text-align: center;
  line-height: 1.5;
  padding: 1.3em 0;
}
.sec_top_4 p {
  font-size: 112.5%;
}
@media screen and (max-width: 1024px) {
  .sec_top_4 h3 {
    font-size: 4.8vw;
  }
  .sec_top_4 h3 span {
    font-size: 4.8vw;
  }
}
@media screen and (max-width: 767px) {
  .sec_top_4 h3 {
    font-size: 5.3vw;
  }
  .sec_top_4 h3 span {
    font-size: 5.7vw;
  }
  .sec_top_4 h3 em {
    border-width: 2px;
  }
  .sec_top_4 .f_box .w33 {
    border: #f39800 solid 2px;
  }
  .sec_top_4 .f_box .w33 p {
    font-size: 6vw;
    padding: 0.7em 0;
  }
}

.sec_top_5 {
  padding: 5% 0 4%;
}
.sec_top_5 h2 {
  font-size: 36px;
  line-height: 1.333;
  letter-spacing: -0.04em;
  margin-bottom: 1.3em;
}
.sec_top_5 h2 span {
  font-size: 60px;
  color: #f39800;
  display: inline;
}
.sec_top_5 h2::after {
  content: "Features";
  bottom: 0.05em;
}
.sec_top_5 p.more {
  padding-top: 5%;
}
.sec_top_5 p.more a {
  border-radius: 6px;
  font-size: 20px;
  font-weight: 600;
  max-width: 400px;
  margin: 0 auto;
  padding: 1.3em 0;
}
@media screen and (max-width: 1024px) {
  .sec_top_5 h2::after {
    bottom: 2vw;
  }
}
@media screen and (max-width: 767px) {
  .sec_top_5 h2 {
    font-size: 7.4vw;
  }
  .sec_top_5 h2 span {
    font-size: 10.4vw;
  }
}

.box_2 {
  position: relative;
  margin-bottom: 9.7%;
}
.box_2 figure {
  text-align: left;
}
.box_2 .txt {
  position: absolute;
  background-color: #f4f4f4;
  width: 45%;
  padding: 0 2% 5% 4.5%;
  top: 37%;
}
.box_2 .txt h3 {
  position: relative;
  font-family: impact, sans-serif;
  top: -1.55em;
  font-size: 36px;
  line-height: 1.2;
  padding: 0;
  margin-bottom: 0;
  letter-spacing: 0.05em;
  color: #222;
}
.box_2 .txt h3 span {
  font-size: 72px;
}
.box_2 .txt h4 {
  font-size: 30px;
  color: #f39800;
  line-height: 1.2;
  font-weight: 800;
  margin: -1em 0 0.7em;
}
.box_2 .txt p {
  font-size: 20px;
  font-weight: 600;
}
.box_2:nth-last-of-type(odd) .txt {
  right: 0;
}
.box_2:nth-last-of-type(odd) .txt h3 {
  text-align: right;
  margin-right: -2%;
}
.box_2:nth-last-of-type(even) {
  margin-bottom: 13%;
}
.box_2:nth-last-of-type(even) figure {
  text-align: right;
}
.box_2:nth-last-of-type(even) .txt {
  left: 0;
  padding-bottom: 3%;
}
.box_2:nth-last-of-type(even) .txt h3 {
  text-align: left;
  margin-left: -8%;
}
@media screen and (max-width: 1024px) {
  .box_2 .txt h4 {
    font-size: 3vw;
  }
  .box_2 .txt p {
    font-size: 2vw;
  }
}
@media screen and (max-width: 767px) {
  .box_2 figure {
    margin-bottom: 0;
  }
  .box_2 .txt {
    position: relative;
    width: 100%;
  }
  .box_2 .txt h3 {
    top: -1.2em;
    font-size: 8vw;
  }
  .box_2 .txt h4 {
    font-size: 6.3vw;
  }
  .box_2 .txt p {
    font-size: 4.3vw;
  }
  .box_2:nth-last-of-type(odd) .txt h3 {
    margin-right: 3%;
  }
  .box_2:nth-last-of-type(even) .txt h3 {
    margin-left: 0;
  }
}

.sec_top_6 {
  padding: 10.8% 0 3.5%;
  background: url(../img/bg_02.jpg) no-repeat center bottom;
}
.sec_top_6 h2 {
  font-size: 36px;
  line-height: 1.25;
  letter-spacing: -0.04em;
  margin-bottom: 1.3em;
}
.sec_top_6 h2 span {
  font-size: 48px;
  display: inline;
}
.sec_top_6 h2 em {
  color: #f39800;
  font-style: normal;
}
.sec_top_6 h2::after {
  content: "Plan";
  bottom: 0.5em;
}
.sec_top_6 p.t_r {
  font-weight: 700;
  margin-bottom: 3em;
}
.sec_top_6 p.more a.arw {
  max-width: 400px;
  padding: 1.35em 0;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 800;
}
@media screen and (max-width: 1024px) {
  .sec_top_6 {
    background-size: auto 100%;
  }
}
@media screen and (max-width: 767px) {
  .sec_top_6 {
    padding-top: 18%;
  }
  .sec_top_6 h2 {
    font-size: 5.7vw;
  }
  .sec_top_6 h2 span {
    font-size: 7.2vw;
  }
}

.tbl_price {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0.5em;
}
.tbl_price th,
.tbl_price td {
  border-right: #ccc solid 2px;
  border-bottom: #ccc solid 2px;
  width: 25%;
  height: 3.56em;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  line-height: 1.5;
}
.tbl_price th span,
.tbl_price td span {
  font-size: 24px;
}
.tbl_price thead th {
  background-color: #f39800;
  color: #fff;
  border-top: #ccc solid 2px;
  font-size: 24px;
  height: 4.2em;
}
.tbl_price thead th:first-child {
  background: transparent;
  border-top: none;
}
.tbl_price tbody th {
  background-color: #9e9d9d;
  color: #fff;
  border-left: #ccc solid 2px;
  font-size: 24px;
}
.tbl_price tbody td {
  background-color: #fff;
  color: #333;
}
@media screen and (max-width: 1024px) {
  .tbl_price thead th {
    font-size: clamp(13px, 2.5vw, 24px);
  }
  .tbl_price tbody th, .tbl_price tbody td {
    font-size: clamp(13px, 2.7vw, 24px);
  }
  .tbl_price tbody th span, .tbl_price tbody td span {
    font-size: clamp(13px, 2.3vw, 24px);
  }
}
@media screen and (max-width: 767px) {
  .tbl_price th span,
  .tbl_price td span {
    font-size: 3vw;
  }
  .tbl_price thead th {
    font-size: 3vw;
  }
  .tbl_price tbody th {
    font-size: 3vw;
  }
  .tbl_price tbody td {
    font-size: 3vw;
  }
}

.sec_top_7 {
  padding: 9.5% 0 2.7%;
}
.sec_top_7 h2 {
  margin-bottom: 1.5em;
}
.sec_top_7 h2::after {
  content: "Q&A";
}
@media screen and (max-width: 767px) {
  .sec_top_7 {
    padding-top: 22.5%;
  }
}

dl.faq {
  border: #ccc solid 2px;
  padding: 2% 2% 0 4.5%;
}
dl.faq dt,
dl.faq dd {
  position: relative;
}
dl.faq dt span,
dl.faq dd span {
  position: absolute;
  display: block;
  top: 0;
  left: -1.3em;
}
dl.faq dt {
  font-size: 137.5%;
  font-weight: 600;
  color: #f39800;
  background: none;
  padding: 0 0 2%;
  margin-bottom: 0;
}
dl.faq dt::before, dl.faq dt::after {
  background-color: #f39800;
  height: 2px;
  width: 20px;
}
dl.faq dd {
  font-size: 112.5%;
  padding: 3.1% 0 0;
  background: none;
  font-weight: 500;
  line-height: 1.667;
}
dl.faq dd span {
  top: 1.6em;
}
@media screen and (max-width: 767px) {
  dl.faq {
    padding-left: 10.5%;
  }
  dl.faq dt {
    padding-right: 13%;
  }
  dl.faq dd {
    padding-top: 8%;
    margin-bottom: 1em;
  }
}

.sec_top_8 {
  padding: 3.65% 0 3.5%;
  background: url(../img/bg_03.jpg) no-repeat center bottom;
}
.sec_top_8 h3 {
  font-size: 36px;
  text-align: center;
  line-height: 1.333;
  margin-bottom: -0.2em;
}
.sec_top_8 h3 span {
  font-weight: 800;
  font-size: 48px;
  color: #f39800;
}
.sec_top_8 p.t_c {
  margin-bottom: 3.8em;
}
.sec_top_8 .f_box {
  margin-bottom: 6%;
}
.sec_top_8 .f_box .w5 {
  background-color: #fff;
  padding-bottom: 2.3em;
}
.sec_top_8 .f_box .w5 p {
  font-size: 14px;
  text-align: center;
}
.sec_top_8 .f_box .w5 p.ttl {
  text-align: center;
  margin-bottom: 0.5em;
}
.sec_top_8 .f_box .w5 p.ttl span {
  position: relative;
  background-color: #f39800;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  padding: 0.2em 1.2em;
}
.sec_top_8 .f_box .w5 p.num {
  font-size: 72px;
  font-weight: 700;
  text-align: center;
  margin-bottom: -0.15em;
}
.sec_top_8 .f_box .w5 p.num span {
  font-size: 30px;
}
.sec_top_8 .f_box.bnr {
  width: 30%;
  margin: 0 auto;
}
.sec_top_8 figure {
  margin-bottom: 1.5em;
}
.sec_top_8 p.more a.arw {
  max-width: 400px;
  padding: 1.35em 0;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 800;
}
@media screen and (max-width: 767px) {
  .sec_top_8 {
    background-size: auto 100%;
  }
  .sec_top_8 h3 {
    font-size: 5.7vw;
  }
  .sec_top_8 h3 span {
    font-size: 7.3vw;
  }
  .sec_top_8 p.t_c {
    font-size: 3vw;
  }
  .sec_top_8 .f_box .w5 {
    margin-top: 8%;
  }
  .sec_top_8 .f_box .w5 p {
    font-size: 3vw;
  }
  .sec_top_8 .f_box .w5 p.ttl span {
    font-size: 6.3vw;
  }
  .sec_top_8 .f_box .w5 p.num {
    font-size: 16vw;
  }
  .sec_top_8 .f_box.bnr {
    width: 90%;
    flex-flow: row;
    align-items: center;
    margin-bottom: 7%;
  }
  .sec_top_8 .f_box.bnr figure {
    margin: 0;
  }
}

.sec_top_9 {
  padding: 7.3% 0 10%;
}
.sec_top_9 h2 {
  margin-bottom: 1.5em;
}
.sec_top_9 h2.news::after {
  content: "News";
}
.sec_top_9 h2.blog::after {
  content: "Blog";
}
.sec_top_9 ul li {
  margin-bottom: 2.4em;
}
.sec_top_9 ul li span {
  padding-right: 1em;
}
.sec_top_9 ul li a {
  font-size: 18px;
  font-weight: 500;
}
.sec_top_9 p.more a.arw {
  max-width: 400px;
  padding: 1.35em 0;
  margin: 2.6em auto 0;
  font-size: 20px;
  font-weight: 800;
}
@media screen and (max-width: 767px) {
  .sec_top_9 h2 {
    margin-top: 20%;
  }
  .sec_top_9 ul li span {
    display: block;
    margin-bottom: 0.5em;
  }
}

.sec_top_10 {
  padding: 5.3% 0 0;
  background-color: #f4f4f4;
}
.sec_top_10 h3 {
  margin-bottom: 0;
}
.sec_top_10 h3 span {
  font-size: 48px;
}
.sec_top_10 h3 span.clr {
  color: #f39800;
}
.sec_top_10 figure {
  position: relative;
}
.sec_top_10 figure img {
  margin-top: -48%;
}
.sec_top_10 p.more a.arw {
  max-width: 400px;
  padding: 1.35em 0;
  margin: 1em auto 0 1.5em;
  font-size: 20px;
  font-weight: 800;
}
@media screen and (max-width: 1024px) {
  .sec_top_10 figure img {
    width: 70%;
    margin-top: -40%;
  }
}
@media screen and (max-width: 767px) {
  .sec_top_10 {
    padding-top: 10%;
  }
  .sec_top_10 h3 {
    text-align: center;
  }
  .sec_top_10 h3 span {
    font-size: 6vw;
  }
  .sec_top_10 figure img {
    margin-top: -18%;
    width: 50vw;
  }
  .sec_top_10 p.more a.arw {
    max-width: 90vw;
    margin: 0 auto;
  }
}

.sec_top_11 {
  background: url(../img/bg_04.jpg) no-repeat center top;
  padding: 7% 0 2.5%;
}
.sec_top_11 h2 {
  margin-bottom: 1em;
  color: #fff;
}
.sec_top_11 h2::after {
  content: "Contact";
  color: #f39800;
}
.sec_top_11 p {
  text-align: center;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
}
.sec_top_11 .f_box {
  width: 94%;
  margin: 0 auto;
}
.sec_top_11 p.more a.arw {
  max-width: 400px;
  padding: 1.5em 0;
  margin: 0.6em auto 0 1.5em;
  font-size: 20px;
  font-weight: 800;
}
.sec_top_11 p.more a.arw.line {
  background-color: #35bc27;
}
@media screen and (max-width: 1024px) {
  .sec_top_11 p.more a.arw {
    margin: 0.6em auto;
  }
}
@media screen and (max-width: 767px) {
  .sec_top_11 {
    padding: 20% 0 0;
    background-position: 80% top;
  }
  .sec_top_11 p {
    font-size: 4.7vw;
  }
  .sec_top_11 p.more a.arw {
    margin: 0 auto;
    padding: 1em;
  }
}