.privacy{
  h1{
    background: url(../img/h1_about.jpg) no-repeat center top;
    span{
      &::after {
        content: 'Privacy Policy';
      }
    }
    @media screen and (max-width:768px) {
      background-size: cover;
      span{
        &::after {
          font-size: 17vw;
          bottom: 0;
        }
      }
    }
  }
}

.pp_1{
  padding: 0 0 8%;
  h2{
    background-color: #f4f4f4;
    color: #000;
    padding: .4em;
    text-align: center;
    margin-bottom: 1em;
    font-size: 200%;
  }
  h3{
    font-size: 24px;
    margin-bottom: 0;
  }
  ul{
    margin-bottom: 3em;
    li{
      position: relative;
      padding-left: 1em;
      line-height: 1.75em;
      &::before{
        position: absolute;
        content: '・';
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto auto auto 0;
      }
    }
  }
  p{
    margin-bottom: 2em;
    & + p{
      margin-bottom: 1.5em;
    }
    & + ul{
      margin-top: -2em;
    }
  }
  @media screen and (max-width:768px) {
    h2{
      font-size: 130%;
      line-height: 1.5em;
    }
    h3{
      font-size: 130%;
      line-height: 1.5em;
    }
  }
}