header {
  .inner {
    padding: 0 .5% 0 1%;
    position: relative;
    display: flex;
    height: 102px; // ヘッダ全体の高さ
    align-items: center;
  }

  .logo {
    width: 248px;
    height: 64px;
    margin-bottom: -8px;
  }

  .h_info {
    p.more {
      a {
        width: 300px;
        max-width: none;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color_primary;
        border-radius: 6px;
        @include fs(20);
        font-weight: 800;

        img {
          margin-right: 1.6rem;
        }
      }
    }
  }
  @media screen and (max-width:1470px) {
    .logo {
      width: 16%;
      height: auto;
      margin-bottom: -8px;
      img{
        width: 100%;
      }
    }
    .h_info {
      p.more {
        a {
          width: auto;
          max-width: 300px;
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (max-width:1024px) {
    .inner {
      height: 60px;
      padding: 0 5% 0 3%;
    }

    .logo {
      width: 150px;
      height: auto;
      margin-bottom: -9px;

      img {
        width: 100%;
      }
    }

    .h_info {
      display: none;
    }
  }
}

.g_nav {
  margin-left: auto;
  transition-duration: .3s;

  ul {
    display: flex;
    align-items: center;
    padding-top: 5px;

    li {
      margin-right: 37px;
      line-height: 1;

      a {
        position: relative;
        text-decoration: none;
        font-weight: 600;
        @include fs(20);

        span {
          display: block;
          @include fs(14);
          font-weight: 400;
          color: #f39800;
          padding-top: 6px;
        }
      }

      &:last-child {
        margin-right: 48px;
      }
    }
  }
  @media screen and (max-width:1470px) {
    ul li{
      margin-right: 1.5em;
        a{
        font-size: 16px;
        span{
          font-size: 12px;
        }
      }
      &:last-child{
        margin-right: 1em;
      }
    }
  }

  @media screen and (max-width:1024px) {
    display: none;
    position: absolute;
    top: 55px;
    right: 0;
    width: 100%;
    z-index: 10;
    max-width: 40vw;

    ul {
      flex-flow: column;
      width: 100%;
      max-width: 480px;

      li {
        margin: 0;
        display: block;
        width: 100%;

        a {
          display: block;
          background-color: rgba(#666, .9);
          color: #fff;
          width: 100%;
          padding: 1em;
          border-bottom: #fff solid 1px;

          &::before {
            display: none;
          }
        }

        &:first-child {
          a {
            padding-left: 1em;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.sp_menu {
  display: none;

  @media screen and (max-width:1024px) {
    display: block;
    width: 30px;
    height: 23px;
    margin-left: auto;
    position: relative;

    span {
      display: block;
      background-color: $color_primary;
      width: 30px;
      height: 3px;
      position: relative;
      transition-duration: .3s;
      border-radius: 2px;

      &::before,
      &::after {
        position: absolute;
        display: block;
        content: '';
        width: inherit;
        height: inherit;
        background-color: inherit;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 auto auto auto;
      }

      &::before {
        top: 10px;
      }

      &::after {
        top: 20px;
      }
    }

    &.active {
      span {
        transform: rotate(45deg);
        top: 8px;

        &::before,
        &::after {
          transform: rotate(-90deg);
          top: 0;
          left: 0;
        }
      }
    }
  }
}