.about{
  h1{
    background: url(../img/h1_about.jpg) no-repeat center top;
    span{
      &::after {
        content: 'About';
      }
    }
  }
  h2{
    text-align: center;
    font-size: 48px;
    color: #f39800;
    span{
      font-size: 18px;
      letter-spacing: .1em;
      font-weight: 400;
      padding-bottom: .6em;
    }
  }
}

.abt_1{
  background: url(../img/bg_about_1.jpg) no-repeat center top;
  padding-top: 10px;
  p{
    font-size: 18px;
  }
}

.abt_box_1{
  background: url(../img/about_01.png) no-repeat center bottom;
  height: 600px;
  width: 100%;
  position: relative;
  .txt_1, .txt_2, .txt_3, .txt_4, .txt_5{
    position: absolute;
    p{
      font-size: 22px;
      text-align: center;
      font-weight: bold;
      line-height: 1.3em;
    }
  }
  .txt_1{
    background: url(../img/about_05.png) no-repeat center bottom;
    top: 60%;
    left: 0;
    padding: 81px 30px 45px 30px;
  }
  .txt_2{
    background: url(../img/about_03.png) no-repeat center bottom;
    top: 14%;
    left: .5%;
    padding: 60px 55px 80px 55px;
  }
  .txt_3{
    background: url(../img/about_02.png) no-repeat center bottom;
    top: 2%;
    left: 27%;
    padding: 29px 58px 41px 52px;
  }
  .txt_4{
    background: url(../img/about_04.png) no-repeat center bottom;
    top: 1%;
    left: 64%;
    padding: 60px 55px 80px 55px;
  }
  .txt_5{
    background: url(../img/about_06.png) no-repeat center bottom;
    top: 58%;
    left: 67%;
    padding: 65px 30px 52px 30px;
  }
}

.abt_2{
  figure{
    margin-bottom: 20px;
  }
  p{
    font-size: 18px;
    text-align: center;
    margin-bottom: 7%;
    &.t_r{
      text-align: right;
    }
    &.ex_lrg span{
      color: #f39800;
      font-size: 30px;
      font-weight: 500;
    }
  }
  .w8{
    h3.fukidashi{
      background: url(../img/about_10.png) no-repeat center top;
      padding: 44px 1em;
      text-align: center;
      margin-bottom: .8em;
    }
    p{
      text-align: left;
      padding-left: 4em;
    }
  }
}

.abt_box_2{
  background-color: #f4f4f4;
  padding: 4%;
  margin-bottom: 5%;
  h3{
    font-size: 36px;
    display: block;
    margin: 0 auto 1.5em;
    text-align: center;
    em{
      font-style: normal;
      padding-bottom: 14px;
      border-bottom: #f39800 solid 3px;
    }
    span{
      color: #f39800;
    }
    &.ul{
      em{
        display: inline-block;
      }
    }
  }
  .ul_x li{
    font-weight: 500;
  }
  .ul_chk li{
    font-size: 18px;
    font-weight: 500;
  }
  &.ext{
    padding-bottom: 7%;
    margin-bottom: 8%;
    h3{
      text-align: left;
    }
    .w35.lrg{
      position: relative;
      img{
        position: absolute;
        max-width: 115%;
        top: -150%;
        left: -10%;
      }
    }
  }
}

.abt_box_3{
  border: #f4f4f4 solid 20px;
  padding: 4%;
  h3{
    font-size: 30px;
    span{
      font-size: 36px;
    }
    em{
      font-style: normal;
      font-size: 60px;
      color: #f39800;
      border-bottom: #f39800 double 10px;
    }
  }
  p{
    margin-bottom: 0;
    text-align: left;
  }
}

.abt_3{
  h3{
    font-size: 36px;
    display: block;
    margin: 0 auto 1.5em;
    text-align: center;
    em{
      font-style: normal;
      padding-bottom: 14px;
      border-bottom: #f39800 solid 3px;
    }
    span{
      color: #f39800;
    }
    &.ul{
      em{
        display: inline-block;
      }
    }
  }
  p{
    font-size: 18px;
    margin-bottom: 7%;
    & + p{
      margin-top: -4%;
    }
  }
}

.abt_4{
  background-color: #f4f4f4;
  h2{
    margin-bottom: .75em;
  }
}

.abt_box_4{
  background-color: #fff;
  padding: 5%;
  margin-bottom: 7%;
  &::after{
    content: "";
    display: block;
    clear: both;
  }
  .f_r{
    margin: 0 0 6% 6%;
  }
  dl{
    width: auto;
    dt{
      display: table;
      overflow: hidden;
      font-size: 22px;
      font-weight: 500;
      color: #f39800;
      position: relative;
      padding: .5em 0 1em;
      &::after{
        position: relative;
        content: '';
        display: inline-block;
        width: 148px;
        height: 1px;
        background-color: #f39800;
        margin: 0 0 .4em 15px;
      }
    }
    dd{
      line-height: 1.65em;
      margin-bottom: 2em;
      p{
        margin-bottom: 1em;
        font-size: 18px;
        strong{
          font-weight: bold;
        }
      }
    }
  }
}

@media screen and (max-width:767px) {
  .about{
    h1{
      background-size: auto 100%;
    }
    h2{
      font-size: 5.7vw;
      span{
        font-size: 3.4vw;
      }
    }
  }
  .abt_1{
    background-size: auto 100%;
    p{
    font-size: 4.3vw;
    }
  }
  .abt_box_1{
    background-size: 48% auto;
    height: 80vw;
    .txt_1, .txt_2, .txt_3, .txt_4, .txt_5{
      p{
        font-size: 2.7vw;
      }
    }
    .txt_1{
      background-size: 100% auto;
      top: 66%;
      left: 0;
      padding: 9% 4% 7% 4%;
    }
    .txt_2{
      background-size: 100% auto;
      top: 15%;
      left: 0;
      padding: 6% 4% 9% 5%;
    }
    .txt_3{
      background-size: 100% auto;
      top: 1%;
      left: 30%;
      padding: 4% 7% 5% 6%;
    }
    .txt_4{
      background-size: 100% auto;
      top: 13%;
      left: 60%;
      padding: 7% 2% 8% 4%;
      width: 40%;
    }
    .txt_5{
      background-size: 100% auto;
      top: 58%;
      left: 60%;
      padding: 10% 2% 6% 3%;
      width: 40%;
    }
  }
  .abt_2{
    p{
      font-size: 4.3vw;
      margin-bottom: 7%;
      &.ex_lrg span{
        font-size: 6.3vw;
      }
    }
    .w8{
      h3.fukidashi{
        background-size: 100% 100%;
        padding: 5% 5% 8%;
      }
      p{
        padding: 0 1em;
      }
    }
  }
  .abt_box_2{
    padding: 4%;
    margin-bottom: 5%;
    h3{
      span{
        font-size: 4.7vw;
      }
      em{
        line-height: 1.65em;
        display: block;
        font-size: 4.7vw;
        padding-bottom: .3em;
        border-bottom: #f39800 solid 2px;
      }
    }
    .ul_x li{
      font-size: 4.3vw;
      margin-bottom: 1em;
    }
    figure.w35 img{
      width: 80%;
    }
    .ul_chk li{
      font-size: 4.3vw;
      padding: .2em 0 .3em 1.7em;
    }
    &.ext{
      padding-bottom: 7%;
      margin-bottom: 8%;
      h3{
        text-align: left;
        &.ul{
          text-align: center;
        }
      }
      .w35.lrg img{
        position: relative;
        max-width: 100%;
        top: 0;
        left: 0;
        margin-bottom: -15%;
      }
    }
  }
  .abt_box_3{
    border: #f4f4f4 solid 10px;
    h3{
      font-size: 5.4vw;
      line-height: 1.8em;
      span{
        font-size: 120%;
      }
      em{
        font-size: 200%;
        border-bottom: #f39800 double 6px;
      }
    }
  }
  .abt_3{
    h3{
      span{
        font-size: 4.7vw;
      }
      em{
        line-height: 1.65em;
        display: block;
        font-size: 4.7vw;
        padding-bottom: .3em;
        border-bottom: #f39800 solid 2px;
      }
      &.ul{
        em{
          display: inline-block;
        }
      }
    }
    p{
      font-size: 4.3vw;
    }
  }
  .abt_box_4{
    background-color: #fff;
    padding: 5%;
    margin-bottom: 7%;
    .f_r{
      margin: 0 auto 6%;
      img{
        width: 70%;
      }
    }
    dl{
      dt{
        font-size: 5.3vw;
        padding: .5em 0 1em;
        &::after{
          width: 40vw;
          margin: 0 0 .4em 15px;
        }
      }
      dd{
        p{
          font-size: 4.3vw;
        }
      }
    }
  }
}