.sitemap{
  h1{
    background: url(../img/h1_about.jpg) no-repeat center top;
    span{
      &::after {
        content: "Sitemap";
      }
    }
    @media screen and (max-width:768px) {
      background-size: cover;
      span{
        &::after {
          font-size: 17vw;
          bottom: 0;
        }
      }
    }
  }
}

.sitemap_1{
  padding: 3% 0 5%;
}

.sitemap_ul{
  margin-bottom: 30px;
  margin-left: 30px;
  line-height: 50px;
  a{
    border-bottom: solid 1px;
  }
  li{
    list-style: disc;
    font-weight: bold;
    font-size: 30px;
  }
  @media screen and (min-width: 1300px){
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    max-height: 20em;
  }
  @media screen and (max-width: 767px){
    li{
      font-size: 5.4vw;
    }
  }  
}