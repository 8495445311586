.wpcf7-form{
  input[type="submit"]{
    width: 100%;
    max-width: 280px;
    @include fs(20);
    text-align: center;
    padding: 1em;
    display: block;
    margin: 0 auto;
  }
}

.form_dl{
  display: flex;
  flex-flow: wrap;
  border-top: #ccc solid 1px;
  margin-bottom: 5%;
  dt, dd{
    border-bottom: #ccc solid 1px;
    input, textarea{
      padding: .8em;
      background-color: #fcfcfc;
      border: #eee solid 1px;
      width: 100%;
    }
    &.zip, &.tel{
      input{
        width: 40%;
      }
    }
  }
  dt{
    width: 30%;
    position: relative;
    padding: 1.8em 1em;
    span{
      background-color: $color_primary;
      padding: .3em .2em .2em;
      position: absolute;
      top: 1.5em;
      right: 1em;
      color: #fff;
      &.fre{
        background-color: #ccc;
        color: #000;
      }
    }
  }
  dd{
    padding: 1em;
    width: 70%;
  }
}