.law{
  h1{
    background: url(../img/h1_about.jpg) no-repeat center top;
    span{
      &::after {
        content: 'Specific Trade Law';
      }
    }
  }
  h2{
    text-align: center;
    font-size: 48px;
    color: #f39800;
    span{
      font-size: 18px;
      letter-spacing: .1em;
      font-weight: 400;
      padding-bottom: .6em;
    }
  }
}

.law_1{
  padding-top: 0;
}



@media screen and (max-width:767px) {
  .law{
    h1{
      background-size: auto 100%;
      span{
        &::after {
          font-size: 14vw;
          bottom: 4%;
        }
      }
    }
    h2{
      font-size: 5.7vw;
      span{
        font-size: 3.4vw;
      }
    }
  }
}