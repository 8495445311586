.flow{
  h1{
    background: url(../img/h1_flow.jpg) no-repeat center top;
    span{
      &::after {
        content: 'Flow';
      }
    }
  }
}

.flw_1{
  padding-top: 0;
  margin-bottom: 8.5%;
  h2{
    background-color: #f39800;
    color: #fff;
    margin-bottom: 0;
    font-size: 24px;
    letter-spacing: .1em;
    font-weight: 500;
    padding: .9em 0;
  }
  .flw_box_wrapper{
    padding: 20px 0 60px;
    position: relative;
    &::before{
      position: absolute;
      display: block;
      content: '';
      width: 2px;
      height: inherit;
      background-color: #f39800;
      top: 0;
      right: 0;
      bottom: 0;
      left: 6.6%;
      margin: 0 auto 0 0;
      z-index: 1;
    }
    .flw_box{
      padding: 40px 50px 70px 22px;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      .txt_box {
        width: 51.5%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .step {
          text-align: center;
          border-top: #f39800 solid 2px;
          border-bottom: #f39800 solid 2px;
          background-color: #fff;
          color: #f39800;
          position: relative;
          z-index: 2;
          padding: 18px 0;
          line-height: 1;
          letter-spacing: .1em;
          font-size: 18px;
          width: 18.5%;
          font-weight: 500;
          span{
            display: block;
            font-size: 200%;
            letter-spacing: 0;
            margin: 0 auto;
            text-align: center;
            padding-top: 3px;
          }
        }
        .txt {
          width: 74.5%;
          h3{
            font-size: 30px;
            margin-bottom: 1em;
          }
          p{
            font-size: 18px;
          }
        }
      }
      figure{
        width: 48%;
      }
      &:nth-of-type(even){
        background-color: #f4f4f4;
        .txt_box .step{
          background-color: #f4f4f4;
        }
      }
      &::before{
        position: absolute;
        display: block;
        content: '';
        width: 2px;
        height: inherit;
        background-color: #f39800;
        top: 0;
        right: 0;
        bottom: 0;
        left: 6.6%;
        margin: 0 auto 0 0;
        z-index: 1;
      }
    }
  }
}

@media screen and (max-width:767px) {
  .flow{
    h1{
      background-size: auto 100%;
    }
  }
  .flw_1 {
    h2{
      font-size: 6.3vw;
    }
    .flw_box_wrapper{
      &::before{
        left: 8%;
      }
      .flw_box{
        flex-flow: column;
        padding: 4vw 5vw 7vw 2vw;
        .txt_box{
          width: 100%;
          .step {
            font-size: 3.7vw;
            padding: 1em 0;
            width: 14%;
          }
          .txt{
            width: 80%;
            margin-bottom: 8%;
            h3{
              font-size: 6.3vw;
              margin-bottom: .5em;
            }
            p{
              font-size: 4.3vw;
            }
          }
        }
        figure{
          width: 80%;
          margin-right: 0;
        }
        &::before{
          left: 8%;
        }
      }
    }
  }
}