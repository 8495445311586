@mixin fs($p) {
  font-size: #{$p}px;
  font-size: #{calc($p / 10)}rem;
}

@mixin fm() {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

@mixin fg() {
  font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック体", "Yu Gothic", YuGothic, "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic";
}